import React, { useState, useEffect } from 'react';
import { Alert } from '@mui/material';

const ErrorBox = ({ message, onClose }) => {
  const [open, setOpen] = useState(false); // Başlangıçta açık

  const handleClose = () => {
    setOpen(false);
    onClose(); // Kapatıldığında onClose fonksiyonunu çağır
  };

  useEffect(() => {
    if (message) {
      setOpen(true); // Mesaj varsa açık ol
      const timer = setTimeout(() => {
        setOpen(false); // Belirli bir süre sonra kapat
        onClose(); // Zamanlayıcı bittiğinde onClose fonksiyonunu çağır
      }, 5000);
      return () => clearTimeout(timer); // Temizlik yap
    }
  }, [message, onClose]); // Mesaj değiştiğinde değil, herhangi bir mesaj geldiğinde etkinleştir

  return (
    <div style={{ position: 'fixed', top: '20px', left: '50%', transform: 'translateX(-50%)', zIndex: 999 }}>
      {open && (
        <Alert severity="error" onClose={handleClose}>
          {message}
        </Alert>
      )}
    </div>
  );
};

export default ErrorBox;
