import React from 'react'
import { Link } from 'react-router-dom'
import { FaDiscord } from "react-icons/fa";
import { LinkedIn, YouTube, WhatsApp, Facebook, Instagram } from '@mui/icons-material'
import XIcon from '@mui/icons-material/X';
import Duyuru from './Components/Duyuru';
import akgun from "./Components/akgun.png"
import fipek from "./Components/fipek.png"
import Afis from "./Components/Afis.png"

function Mainpage() {




  
  return (
    <>
    
  
  <Duyuru isOpenByDefault={true}>
    <div className="flex flex-col items-center justify-center">
    <img
          src={Afis}
          alt="afiş"
          className="w-full h-auto max-h-[70vh] rounded-lg"
        />

        {/* Başvur Butonu */}
        <Link
          to="/acikpozisyonlar"
          className="bg-primary text-white font-semibold py-2 px-6 rounded-lg hover:bg-secondary transition duration-300 mt-4"
        >
          Aramıza Katıl
        </Link>
        </div>
  </Duyuru>
  <div className="hero">
    <div className="f-container-regular z-0">
      <div className="f-header-title-wrapper-center">
        <div className="f-margin-bottom-24">
          <h1 className="f-h1-heading">
            <span className="text-span">UFK</span> Teknoloji Takımı
          </h1>
        </div>

        <p className="f-paragraph-large">
          UFK Teknoloji Takımı adı altında, 2023 yılından itibaren teknolojik
          yenilikler ve fikirler üretmek üzere çalışmalar yapıyoruz. Yapay zeka
          üzerine çeşitli çalışmalar yaparak tecrübeler edinen ekibimiz, Milli
          Teknoloji Hamlesi'ne katkı sağlayacak şekilde yapay zeka teknolojileri
          alanında projeler yapmayı ve yerli ve milli uygulamalar geliştirmeyi
          hedeflemektedir.
        </p>

        <p className='items-center f-footer-detail  mt-12 '>Sosyal Medya Hesaplarımız</p>
            <div className="flex flex-wrap justify-center gap-5 mt-5 -mb-12">
            
              <Link to="https://instagram.com/ufktakimi" className="f-footer-social w-inline-block">
                <div className="f-icon-regular w-embed">
                <Instagram
                  loading="lazy"
                  width={27}
                  alt=""
                  className="f-icon-regular"
                />
                </div>
              </Link>
              <Link to="https://x.com/ufktakimi" className="f-footer-social w-inline-block">
                <div className="f-icon-regular w-embed">
                <XIcon
                  loading="lazy"
                  width={27}
                  alt=""
                  className="f-icon-regular"
                />
                </div>
              </Link>
              <Link to="https://linkedin.com/company/ufktakimi" className="f-footer-social w-inline-block hover:text-white">
                <LinkedIn
                  loading="lazy"
                  width={27}
                  alt=""
                  className="f-icon-regular"
                />
              </Link>
              <Link to="https://www.facebook.com/profile.php?id=61558610344704" className="f-footer-social w-inline-block hover:text-white">
                <Facebook
                  loading="lazy"
                  width={27}
                  alt=""
                  className="f-icon-regular"
                />
              </Link>
              <Link to="https://www.youtube.com/@ufktakimi" className="f-footer-social w-inline-block hover:text-white">
                <YouTube
                  loading="lazy"
                  width={27}
                  alt=""
                  className="f-icon-regular"
                />
              </Link>
              <Link to="https://discord.gg/MzBvGKXQ9J" className="f-footer-social w-inline-block hover:text-white">
                <FaDiscord
                  loading="lazy"
                  width={27}
                  alt=""
                  className="f-icon-regular"
                />
              </Link>
              <Link to="https://chat.whatsapp.com/EWVC77LjU4N3iEPQlcWoHB" className="f-footer-social w-inline-block hover:text-white">
                <WhatsApp
                  loading="lazy"
                  width={27}
                  alt=""
                  className="f-icon-regular"
                />
              </Link>
            
        </div>
      </div>
    </div>
  </div>
  <section className="section-large yer-stasyonu">
    <div className="container-regular">
      <div className="w-layout-grid content-grid">
        <div className="content-wrapper">
          <div className="wrap-v-regular align-v-l">
            <div className="wrap-v-small">
              <h2 className="h2-heading-2">Yer İstasyonu Yazılımımız</h2>
              <p className="paragraph-x-large text-color-gray-800">
                Yer İstasyonu Yazılımımız, İHA’mızı yerden kontrol ve takip
                etmemize olanak tanıyan bir sistemdir. Yazılımımız sayesinde:{" "}
                <br />
                <br />
                <span className="text-span-3">
                  • İHA'nın anlık konumu, kamera görüntüsü gibi verileri takip
                  edilebilir.
                  <br />
                  <br />• İHA'nın otonom uçuş görevleri ve manuel kontrolü
                  gerçekleştirilebilir.
                  <br />
                  <br />• Görev planlaması ve izleme işlemleri yapılabilir.
                </span>
                <br />
              </p>
            </div>
            <Link to="/" className="bg-[#135361] text-white items-center flex justify-center py-2 rounded-xl font-bold text-lg">
              Demo (Yakında)
            </Link>
          </div>
        </div>
        <img
          src="https://cdn.prod.website-files.com/66945ac04a8680c762a4f7c7/66bb22f40059d0b1099bb45e_yizufk.png"
          loading="lazy"
          sizes="(max-width: 767px) 90vw, 570px"
          srcSet="https://cdn.prod.website-files.com/66945ac04a8680c762a4f7c7/66bb22f40059d0b1099bb45e_yizufk-p-500.png 500w, https://cdn.prod.website-files.com/66945ac04a8680c762a4f7c7/66bb22f40059d0b1099bb45e_yizufk-p-800.png 800w, https://cdn.prod.website-files.com/66945ac04a8680c762a4f7c7/66bb22f40059d0b1099bb45e_yizufk-p-1080.png 1080w, https://cdn.prod.website-files.com/66945ac04a8680c762a4f7c7/66bb22f40059d0b1099bb45e_yizufk-p-1600.png 1600w, https://cdn.prod.website-files.com/66945ac04a8680c762a4f7c7/66bb22f40059d0b1099bb45e_yizufk-p-2000.png 2000w, https://cdn.prod.website-files.com/66945ac04a8680c762a4f7c7/66bb22f40059d0b1099bb45e_yizufk.png 2160w"
          alt=""
          className="image-2"
        />
      </div>
    </div>
  </section>
  <section className="section-large">
    <div className="container-regular">
      <div className="w-layout-grid content-grid">
        <div className="content-wrapper">
          <div className="wrap-v-regular align-v-l">
            <div className="wrap-v-small">
              <h2 className="h2-heading">EFSUN İHA</h2>
              <p className="paragraph-x-large text-color-gray-800">
                2024 TEKNOFEST Savaşan İHA yarışması için özel olarak
                tasarladığımız EFSUN İHA, Otonom Takip, Kamikaze ve Hava Savunma
                Sistemleri görevlerini gerçekleştirmesi hedeflenmektedir.{" "}
              </p>
            </div>
          </div>
          <div className="content-testimonial lg:-mt-12">
            <p className="paragraph-regular-2">
              <span className="text-color-orange">Özellikleri</span>
            </p>
            <div className="testimonial-author">
              <div className="wrap-h-tiny">
                <div className="paragraph-small-2">
                  Kalkış Ağırlığı: <strong>6500 gram</strong>
                </div>
                <div className="paragraph-small-2">
                  Kanat Açıklığı: <strong>2000 mm</strong>
                </div>
                <div className="paragraph-small-2">
                  Seyir Hızı: <strong>16 m/s</strong>
                </div>
                <div className="paragraph-small-2">
                  Tutunma Hızı: <strong>9,86 m/s</strong>
                </div>
                <div className="paragraph-small-2">
                  Haberleşme Menzili: <strong>12 km</strong>
                </div>
                <div className="paragraph-small-2">
                  Batarya Kapasitesi: <strong>12000 mAH</strong>
                </div>
                <div className="paragraph-small-2">
                  Kamera Çözünürlüğü: <strong>1920 x 1080</strong>
                </div>
                <div className="paragraph-small-2">
                  Kamera Kare Hızı: <strong>60 FPS</strong>
                </div>
                <div className="paragraph-small-2">
                  Kamera Kamera Görüş Açısı: <strong>88°(D)×75°(H)</strong>
                </div>
              </div>
            </div>
          </div>
        </div>
        <img
          src="https://cdn.prod.website-files.com/66945ac04a8680c762a4f7c7/66bb0c7d7217523adb88d2c0_P1102620.JPG"
          loading="lazy"
          id="w-node-_32e4fd94-fcfd-4f49-20c8-8b5437f32629-62a4f7ce"
          sizes="90vw"
          alt=""
          srcSet="https://cdn.prod.website-files.com/66945ac04a8680c762a4f7c7/66bb0c7d7217523adb88d2c0_P1102620-p-500.jpg 500w, https://cdn.prod.website-files.com/66945ac04a8680c762a4f7c7/66bb0c7d7217523adb88d2c0_P1102620-p-800.jpg 800w, https://cdn.prod.website-files.com/66945ac04a8680c762a4f7c7/66bb0c7d7217523adb88d2c0_P1102620-p-1080.jpg 1080w, https://cdn.prod.website-files.com/66945ac04a8680c762a4f7c7/66bb0c7d7217523adb88d2c0_P1102620-p-1600.jpg 1600w, https://cdn.prod.website-files.com/66945ac04a8680c762a4f7c7/66bb0c7d7217523adb88d2c0_P1102620-p-2000.jpg 2000w, https://cdn.prod.website-files.com/66945ac04a8680c762a4f7c7/66bb0c7d7217523adb88d2c0_P1102620.JPG 2160w"
          className="image"
        />
      </div>
    </div>
  </section>
  <section className="section-small">
    <div className="container-large">
      <div className="title-wrapper">
        <h2 className="h2-heading">
          EFSUN İHA ile
          <br />
          <span className="text-span-2">Ufkun Ötesine</span> Bakıyoruz
        </h2>
      </div>
      <div className="feature-grid-wrapper">
        <div className="w-layout-grid feature-card-grid-top">
          <div
            id="w-node-_746dd699-a464-bbfc-1943-7cf5e6cc0e26-62a4f7ce"
            className="feature-card"
          >
            <div className="icon w-embed">
              <svg
                width={420}
                height={420}
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.18066 12C2.12066 6.88 6.60766 3 11.9997 3C17.3917 3 21.8777 6.88 22.8187 12C21.8787 17.12 17.3917 21 11.9997 21C6.60766 21 2.12166 17.12 1.18066 12V12ZM11.9997 17C13.3257 17 14.5975 16.4732 15.5352 15.5355C16.4729 14.5979 16.9997 13.3261 16.9997 12C16.9997 10.6739 16.4729 9.40215 15.5352 8.46447C14.5975 7.52678 13.3257 7 11.9997 7C10.6736 7 9.40181 7.52678 8.46413 8.46447C7.52645 9.40215 6.99966 10.6739 6.99966 12C6.99966 13.3261 7.52645 14.5979 8.46413 15.5355C9.40181 16.4732 10.6736 17 11.9997 17ZM11.9997 15C11.204 15 10.441 14.6839 9.87834 14.1213C9.31573 13.5587 8.99966 12.7956 8.99966 12C8.99966 11.2044 9.31573 10.4413 9.87834 9.87868C10.441 9.31607 11.204 9 11.9997 9C12.7953 9 13.5584 9.31607 14.121 9.87868C14.6836 10.4413 14.9997 11.2044 14.9997 12C14.9997 12.7956 14.6836 13.5587 14.121 14.1213C13.5584 14.6839 12.7953 15 11.9997 15Z"
                  fill="currentColor"
                />
              </svg>
            </div>
            <div className="feature-card-text">
              <div className="paragraph-large-bg text-weight-bold">
                Hedef Odaklı Tasarım
              </div>
              <div className="paragraph-regular-2 text-color-gray-600">
                EFSUN İHA, 2024 TEKNOFEST Savaşan İHA yarışması için özel olarak
                tasarlanmış ve geliştirilmektedir.
              </div>
            </div>
          </div>
          <div
            id="w-node-_746dd699-a464-bbfc-1943-7cf5e6cc0e2d-62a4f7ce"
            className="feature-card"
          >
            <div className="icon-4 w-embed">
              <svg
                width={420}
                height={420}
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12 11C13.3261 11 14.5979 11.5268 15.5355 12.4645C16.4732 13.4021 17 14.6739 17 16V22H7V16C7 14.6739 7.52678 13.4021 8.46447 12.4645C9.40215 11.5268 10.6739 11 12 11ZM5.288 14.006C5.12886 14.5428 5.03485 15.0968 5.008 15.656L5 16V22H2V17.5C1.9998 16.6376 2.31803 15.8054 2.89363 15.1632C3.46924 14.521 4.2617 14.1139 5.119 14.02L5.289 14.006H5.288ZM18.712 14.006C19.6019 14.0602 20.4376 14.452 21.0486 15.1012C21.6596 15.7505 21.9999 16.6084 22 17.5V22H19V16C19 15.307 18.9 14.638 18.712 14.006ZM5.5 8C6.16304 8 6.79893 8.26339 7.26777 8.73223C7.73661 9.20107 8 9.83696 8 10.5C8 11.163 7.73661 11.7989 7.26777 12.2678C6.79893 12.7366 6.16304 13 5.5 13C4.83696 13 4.20107 12.7366 3.73223 12.2678C3.26339 11.7989 3 11.163 3 10.5C3 9.83696 3.26339 9.20107 3.73223 8.73223C4.20107 8.26339 4.83696 8 5.5 8V8ZM18.5 8C19.163 8 19.7989 8.26339 20.2678 8.73223C20.7366 9.20107 21 9.83696 21 10.5C21 11.163 20.7366 11.7989 20.2678 12.2678C19.7989 12.7366 19.163 13 18.5 13C17.837 13 17.2011 12.7366 16.7322 12.2678C16.2634 11.7989 16 11.163 16 10.5C16 9.83696 16.2634 9.20107 16.7322 8.73223C17.2011 8.26339 17.837 8 18.5 8V8ZM12 2C13.0609 2 14.0783 2.42143 14.8284 3.17157C15.5786 3.92172 16 4.93913 16 6C16 7.06087 15.5786 8.07828 14.8284 8.82843C14.0783 9.57857 13.0609 10 12 10C10.9391 10 9.92172 9.57857 9.17157 8.82843C8.42143 8.07828 8 7.06087 8 6C8 4.93913 8.42143 3.92172 9.17157 3.17157C9.92172 2.42143 10.9391 2 12 2V2Z"
                  fill="currentColor"
                />
              </svg>
            </div>
            <div className="feature-card-text">
              <div className="paragraph-large-bg text-weight-bold">
                Çok Disiplinli Yaklaşım
              </div>
              <div className="paragraph-regular-2 text-color-gray-600">
                Mühendislik, yazılım ve tasarım gibi farklı disiplinlerden
                öğrencileri bir araya getirerek bütünsel bir yaklaşım
                sergiliyoruz.
              </div>
            </div>
          </div>
          <div
            id="w-node-_746dd699-a464-bbfc-1943-7cf5e6cc0e34-62a4f7ce"
            className="feature-card"
          >
            <div className="icon-5 w-embed">
              <svg
                width={420}
                height={420}
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.116 20.087C4.84003 19.1599 3.80182 17.9437 3.08639 16.538C2.37097 15.1324 1.99867 13.5772 2 12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12C22.0013 13.5772 21.629 15.1324 20.9136 16.538C20.1982 17.9437 19.16 19.1599 17.884 20.087L16.869 18.348C18.1954 17.3306 19.1698 15.9232 19.6552 14.3236C20.1407 12.724 20.1129 11.0125 19.5757 9.42947C19.0385 7.84648 18.0189 6.47153 16.6601 5.49776C15.3013 4.524 13.6717 4.00034 12 4.00034C10.3283 4.00034 8.69867 4.524 7.3399 5.49776C5.98114 6.47153 4.96154 7.84648 4.42434 9.42947C3.88714 11.0125 3.85932 12.724 4.34478 14.3236C4.83025 15.9232 5.80461 17.3306 7.131 18.348L6.116 20.087V20.087ZM8.15 16.602C7.20503 15.8114 6.52634 14.7491 6.20616 13.5594C5.88597 12.3696 5.9398 11.1102 6.36035 9.9521C6.78089 8.79403 7.54776 7.7935 8.55675 7.08644C9.56574 6.37939 10.7679 6.0001 12 6.0001C13.2321 6.0001 14.4343 6.37939 15.4433 7.08644C16.4523 7.7935 17.2191 8.79403 17.6397 9.9521C18.0602 11.1102 18.114 12.3696 17.7939 13.5594C17.4737 14.7491 16.795 15.8114 15.85 16.602L14.82 14.836C15.3818 14.2775 15.7651 13.5649 15.9214 12.7883C16.0777 12.0117 15.9998 11.2062 15.6978 10.4739C15.3957 9.74165 14.8831 9.11552 14.2248 8.67495C13.5664 8.23437 12.7921 7.99917 12 7.99917C11.2079 7.99917 10.4336 8.23437 9.77525 8.67495C9.11694 9.11552 8.60426 9.74165 8.30221 10.4739C8.00016 11.2062 7.92234 12.0117 8.07861 12.7883C8.23488 13.5649 8.61821 14.2775 9.18 14.836L8.15 16.602V16.602ZM11 13H13L14 22H10L11 13Z"
                  fill="currentColor"
                />
              </svg>
            </div>
            <div className="feature-card-text">
              <div className="paragraph-large-bg text-weight-bold">
                Gelişmiş Haberleşme
              </div>
              <div className="paragraph-regular-2 text-color-gray-600">
                EFSUN İHA, 12 km'lik bir haberleşme menziline sahip olacak
                şekilde tasarlanmaktadır.
              </div>
            </div>
          </div>
        </div>
        <div className="w-layout-grid feature-card-grid-bottom">
          <div
            id="w-node-_746dd699-a464-bbfc-1943-7cf5e6cc0e3c-62a4f7ce"
            className="feature-card"
          >
            <div className="icon-3 w-embed">
              <svg
                width={420}
                height={420}
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4 16V20H8V22H2V16H4ZM22 16V22H16V20H20V16H22ZM7.5 7C7.5 8.19347 7.97411 9.33807 8.81802 10.182C9.66193 11.0259 10.8065 11.5 12 11.5C13.1935 11.5 14.3381 11.0259 15.182 10.182C16.0259 9.33807 16.5 8.19347 16.5 7H18.5C18.5002 8.18939 18.1741 9.35605 17.5571 10.3729C16.9402 11.3898 16.056 12.2179 15.001 12.767L15 19H9V12.768C7.94462 12.2189 7.06018 11.3907 6.44303 10.3737C5.82587 9.3566 5.49969 8.18967 5.5 7H7.5ZM12 5C12.663 5 13.2989 5.26339 13.7678 5.73223C14.2366 6.20107 14.5 6.83696 14.5 7.5C14.5 8.16304 14.2366 8.79893 13.7678 9.26777C13.2989 9.73661 12.663 10 12 10C11.337 10 10.7011 9.73661 10.2322 9.26777C9.76339 8.79893 9.5 8.16304 9.5 7.5C9.5 6.83696 9.76339 6.20107 10.2322 5.73223C10.7011 5.26339 11.337 5 12 5ZM8 2V4L4 3.999V8H2V2H8ZM22 2V8H20V4H16V2H22Z"
                  fill="currentColor"
                />
              </svg>
            </div>
            <div className="feature-card-text">
              <div className="paragraph-large-bg text-weight-bold">
                Yüksek Çözünürlüklü Görüntüleme
              </div>
              <div className="paragraph-regular-2 text-color-gray-600">
                EFSUN İHA için 920x1080 çözünürlükte, 60 FPS kare hızında ve
                88°(D)x75°(H) görüş açısına sahip kamera sistemi
                planlanmaktadır.
              </div>
            </div>
          </div>
          <div
            id="w-node-_746dd699-a464-bbfc-1943-7cf5e6cc0e43-62a4f7ce"
            className="feature-card"
          >
            <div className="icon-2 w-embed">
              <svg
                width={420}
                height={420}
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14.1214 10.4801C13.9339 10.2926 13.6796 10.1873 13.4144 10.1873C13.1493 10.1873 12.895 10.2926 12.7074 10.4801L12.0004 11.1861C11.816 11.3771 11.5953 11.5295 11.3513 11.6343C11.1072 11.7391 10.8448 11.7943 10.5793 11.7966C10.3137 11.7989 10.0503 11.7483 9.80454 11.6478C9.55875 11.5472 9.33544 11.3987 9.14766 11.2109C8.95987 11.0231 8.81137 10.7998 8.7108 10.554C8.61024 10.3082 8.55964 10.0449 8.56195 9.77931C8.56425 9.51375 8.61943 9.25131 8.72424 9.0073C8.82906 8.76329 8.98143 8.5426 9.17245 8.35811L14.8024 2.72611C16.1354 2.42294 17.53 2.54771 18.7879 3.08269C20.0458 3.61766 21.1031 4.53561 21.8093 5.706C22.5156 6.8764 22.8349 8.23966 22.7218 9.60194C22.6087 10.9642 22.069 12.2562 21.1794 13.2941L19.0714 15.4291L14.1214 10.4791V10.4801ZM3.16145 4.46811C4.19621 3.43349 5.54967 2.77809 7.00301 2.60788C8.45635 2.43766 9.92462 2.76259 11.1704 3.53011L7.75745 6.94411C7.01825 7.68184 6.59688 8.67941 6.58339 9.72367C6.56989 10.7679 6.96534 11.7761 7.68523 12.5326C8.40512 13.2892 9.39236 13.7343 10.436 13.7727C11.4796 13.811 12.4969 13.4398 13.2704 12.7381L13.4144 12.6011L17.6574 16.8431L13.4144 21.0861C13.0394 21.4611 12.5308 21.6717 12.0004 21.6717C11.4701 21.6717 10.9615 21.4611 10.5864 21.0861L3.16045 13.6601C1.94158 12.4411 1.25684 10.7879 1.25684 9.06411C1.25684 7.3403 1.94158 5.68708 3.16045 4.46811H3.16145Z"
                  fill="currentColor"
                />
              </svg>
            </div>
            <div className="feature-card-text">
              <div className="paragraph-large-bg text-weight-bold">
                Açık İnovasyon
              </div>
              <div className="paragraph-regular-2 text-color-gray-600">
                Diğer üniversite takımları ve sektör profesyonelleriyle bilgi
                paylaşımına açık, işbirlikçi bir yaklaşım benimsiyoruz.
              </div>
            </div>
          </div>
          <div
            id="w-node-_746dd699-a464-bbfc-1943-7cf5e6cc0e4a-62a4f7ce"
            className="feature-card"
          >
            <img
              src="https://cdn.prod.website-files.com/66945ac04a8680c762a4f7c7/66950b16e41131af0c6aa27a_turkey.png"
              loading="lazy"
              width={40}
              sizes="40px"
              alt=""
              srcSet="https://cdn.prod.website-files.com/66945ac04a8680c762a4f7c7/66950b16e41131af0c6aa27a_turkey-p-500.png 500w, https://cdn.prod.website-files.com/66945ac04a8680c762a4f7c7/66950b16e41131af0c6aa27a_turkey.png 512w"
            />
            <div className="feature-card-text">
              <div className="paragraph-large-bg text-weight-bold">
                Yerli Teknoloji Geliştirme Hedefi{" "}
              </div>
              <div className="paragraph-regular-2 text-color-gray-600">
                Yapay zeka ve otonom sistemler alanında yerli ve milli
                uygulamalar geliştirmeyi amaçlamaktayız.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <div className="f-section-regular-2">
    <div className="f-container-regular">
      <div className="f-margin-bottom-74">
        <div className="f-cta-text-wrapper-center">
          <div className="f-margin-bottom-73">
            <h2 className="f-h2-heading-2">2024 Yılı Sponsorlarımız</h2>
          </div>
          <div className="f-margin-bottom-48">
            <p className="f-paragraph-large-2">
              Desteğini bizlerde esirgemeyen sponsorlarımız.
            </p>
          </div>
        </div>
      </div>
      <div className="w-layout-grid f-grid-four-columns -mt-24">
        <div className="f-cta-logo-card">
          <img
            src="https://cdn.prod.website-files.com/66945ac04a8680c762a4f7c7/6695145fe131a572197d5052_6c3db0cf-633e-4037-9c26-d613e8a9450e.png"
            loading="lazy"
            width={112}
            alt=""
          />
        </div>
        <div className="f-cta-logo-card">
          <img
            src="https://cdn.prod.website-files.com/66945ac04a8680c762a4f7c7/669518743db97fcaa674cc31_image%2013.png"
            loading="lazy"
            width={121}
            alt=""
          />
        </div>
        <div className="f-cta-logo-card">
          <img
            src="https://cdn.prod.website-files.com/66945ac04a8680c762a4f7c7/669514ac06f861444de4a36c_7723a9e7-e777-46aa-bdbe-7cbc6686d730.png"
            loading="lazy"
            width={124}
            alt=""
          />
        </div>
        <div className="f-cta-logo-card">
          <img
            src="https://cdn.prod.website-files.com/66945ac04a8680c762a4f7c7/66951535b5c04d98f703f0fa_image%2011.png"
            loading="lazy"
            width={121}
            alt=""
          />
        </div>
        <div
          id="w-node-_1e3163fa-cce5-d7da-3991-e3c711d34c62-62a4f7ce"
          className="f-cta-logo-card"
        >
          <img
            src="https://cdn.prod.website-files.com/66945ac04a8680c762a4f7c7/669517c98fb35834c3b41385_image%2012.png"
            loading="lazy"
            width={121}
            alt=""
          />
        </div>
        <div
          id="w-node-_826a671a-11b2-74eb-b1f0-13fc9b96037c-62a4f7ce"
          className="f-cta-logo-card"
        >
          <img
            src="https://cdn.prod.website-files.com/66945ac04a8680c762a4f7c7/669517eac83234831cecc4f1_Logo.png"
            loading="lazy"
            width={121}
            alt=""
          />
        </div>
        <div
          id="w-node-_62c0922e-af4b-7f18-3943-029ac29d0de7-62a4f7ce"
          className="f-cta-logo-card"
        >
          <img
            src="https://cdn.prod.website-files.com/66945ac04a8680c762a4f7c7/669518107c7595b5d5349824_image%2014.png"
            loading="lazy"
            width={65}
            alt=""
          />
        </div>
        <div
          id="w-node-_4b70e15e-299e-f7b1-1760-bc6d989a0f17-62a4f7ce"
          className="f-cta-logo-card"
        >
          <img
            src="https://cdn.prod.website-files.com/66945ac04a8680c762a4f7c7/669517c98fb35834c3b41385_image%2012.png"
            loading="lazy"
            width={121}
            alt=""
          />
        </div>
        <div
          id="w-node-_499f1ada-c6d0-c67a-685b-40cebab58db3-62a4f7ce"
          className="f-cta-logo-card"
        >
          <img
            src="https://cdn.prod.website-files.com/66945ac04a8680c762a4f7c7/66951847ef031a67f9d29c26_Group%202.png"
            loading="lazy"
            width={121}
            alt=""
          />
        </div>
        <div
          id="w-node-_815dc524-b352-e27b-972c-55ff2815519a-62a4f7ce"
          className="f-cta-logo-card"
        >
          <img
            src="https://cdn.prod.website-files.com/66945ac04a8680c762a4f7c7/66bb5dac6244234aab187cf5_pikaReTech_logo%20(2)%202.png"
            loading="lazy"
            width={105}
            alt=""
          />
        </div>
        <div className="f-cta-logo-card">
          <img
            src="https://cdn.prod.website-files.com/66945ac04a8680c762a4f7c7/669514dec893f020b8353477_ABMLOGO_PNG_YUKSEK%201.png"
            loading="lazy"
            width={112}
            alt=""
          />
        </div>
        <div
          id="w-node-afcdae78-8f22-dbdf-c7c3-4ff62377d416-62a4f7ce"
          className="f-cta-logo-card"
        >
          <img
            src="https://cdn.prod.website-files.com/66945ac04a8680c762a4f7c7/66bb5dcec663b97c5b3e0deb_image%2019.png"
            loading="lazy"
            width={112}
            alt=""
          />
        </div>
        <div
          id="w-node-_65a4352d-b2e6-8282-cf81-0c751c063f41-62a4f7ce"
          className="f-cta-logo-card"
        >
          <img
            src="https://cdn.prod.website-files.com/66945ac04a8680c762a4f7c7/66bb5df241da6ce5411b83f3_image%2018.png"
            loading="lazy"
            width={112}
            alt=""
          />
        </div>
        <div
          id="w-node-bff373ab-24eb-1d37-61bb-62405458f443-62a4f7ce"
          className="f-cta-logo-card"
        >
          <img
            src="https://cdn.prod.website-files.com/66945ac04a8680c762a4f7c7/66bb5e0dc663b97c5b3e5e3f_C%CC%A7al%C4%B1s%CC%A7ma%20Yu%CC%88zeyi%201%202.png"
            loading="lazy"
            width={112}
            alt=""
          />
        </div>
        <div
          id="w-node-bff373ab-24eb-1d37-61bb-62405458f443-62a4f7ce"
          className="f-cta-logo-card"
        >
          <img
            src={akgun}
            loading="lazy"
            width={112}
            alt=""
          />
        </div>
        <div
          id="w-node-bff373ab-24eb-1d37-61bb-62405458f443-62a4f7ce"
          className="f-cta-logo-card"
        >
          <img
            src={fipek}
            loading="lazy"
            width={112}
            alt=""
          />
        </div>
      </div>
    </div>
  </div>
  <div className="f-section-regular-2 sectionn">
    <div className="f-container-regular">
      <div className="w-layout-grid f-cta-grid-b">
        <div>
          <div className="f-margin-bottom-16">
            <h3 className="f-h3-heading">
              Geleceğin Teknolojilerini Birlikte Geliştirelim
            </h3>
          </div>
          <div className="f-margin-bottom-48">
            <p className="f-paragraph-large-3">
              Sponsorluk ve işbirliği fırsatları için bizimle iletişime geçin.{" "}
              <br />
              Geleceği birlikte inşa edelim!
            </p>
          </div>
          <div className="f-margin-bottom-75">
            <div className="f-cta-form-block w-form">
              <form
                id="email-form"
                name="email-form"
                data-name="Email Form"
                method="get"
                className="f-cta-form-wrapper"
                data-wf-page-id="66945ac04a8680c762a4f7ce"
                data-wf-element-id="d16ff3bc-67d8-7360-62df-d2189b9be62f"
              >
                <Link to="/iletisim" className="f-button-neutral w-button">
                  İletişim
                </Link>
              </form>
              <div className="f-success-message w-form-done">
                <div>Thank you! Your submission has been received!</div>
              </div>
              <div className="w-form-fail">
                <div>Oops! Something went wrong while submitting the form.</div>
              </div>
            </div>
          </div>
        </div>
        <div className="f-cta-image-wrapper">
          <img
            src="https://cdn.prod.website-files.com/66945ac04a8680c762a4f7c7/66bb68f5bb252205b3489d5b_P1102566.jpg"
            loading="lazy"
            sizes="90vw"
            srcSet="https://cdn.prod.website-files.com/66945ac04a8680c762a4f7c7/66bb68f5bb252205b3489d5b_P1102566-p-500.jpg 500w, https://cdn.prod.website-files.com/66945ac04a8680c762a4f7c7/66bb68f5bb252205b3489d5b_P1102566-p-800.jpg 800w, https://cdn.prod.website-files.com/66945ac04a8680c762a4f7c7/66bb68f5bb252205b3489d5b_P1102566-p-1080.jpg 1080w, https://cdn.prod.website-files.com/66945ac04a8680c762a4f7c7/66bb68f5bb252205b3489d5b_P1102566-p-1600.jpg 1600w, https://cdn.prod.website-files.com/66945ac04a8680c762a4f7c7/66bb68f5bb252205b3489d5b_P1102566-p-2000.jpg 2000w, https://cdn.prod.website-files.com/66945ac04a8680c762a4f7c7/66bb68f5bb252205b3489d5b_P1102566-p-2600.jpg 2600w, https://cdn.prod.website-files.com/66945ac04a8680c762a4f7c7/66bb68f5bb252205b3489d5b_P1102566.jpg 3840w"
            alt=""
            className="f-image-cover"
          />
        </div>
      </div>
    </div>
  </div>
</>

  )
}

export default Mainpage