import React, { useState, useEffect } from 'react';
import Logo from './Logo.png';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { AlignJustify, X, User, LogOutIcon, LogIn, UserPlus } from 'lucide-react';
import { Avatar, Menu} from '@mui/material';
import axios from 'axios';

function stringAvatar(name) {
  const nameParts = name.trim().split(' ');
  const initials = nameParts.length > 1 
    ? `${nameParts[0][0]}${nameParts[nameParts.length - 1][0]}` 
    : nameParts[0][0];

  return {
    children: initials.toUpperCase(),
  };
}

function Header() {
  const [isOpen, setIsOpen] = useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [userData, setUserData] = useState(null);
  const [error, setError] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();
  


  const fetchUserData = async () => {
    try {
        const token = localStorage.getItem('token'); // Get token from localStorage

        if (!token) {
            return;
        }

        const response = await axios.get('https://ufkrender.onrender.com/profile', {
            headers: {
                Authorization: `Bearer ${token}`, // Include token in the Authorization header
            },
        });

        setUserData(response.data);
    } catch (err) {
        setError(null);
        localStorage.removeItem('token');
    }
  };

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleAvatarClick = (event) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setMenuAnchorEl(null);
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    setUserData(null);
    navigate('/login');
    handleCloseMenu();
  };

  useEffect(() => {
    fetchUserData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 768) {
        setIsOpen(false);
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  if (error) return <div className="">{error}</div>

  return (
    <div className="p-5 flex items-center gap-3 w-full container z-10 bg-white rounded-full border ">
      <Link to="/" className='flex gap-3 items-center'>
        <img src={Logo} alt="Logo" className="h-10" />
        <div className="flex gap-1 mt-2">
          <p className="text-[#ED762F] font-black text-md">UFK</p>
          <p className="text-[#135361] font-bold text-md">Teknoloji Takımı</p>
        </div>
      </Link>

      <div className="ml-auto hidden md:flex md:gap-5 items-center">
        <Link
          to="/"
          className={`block py-2 px-4 font-bold duration-300 rounded-lg text-center ${location.pathname === '/' ? 'bg-[#ED762F] text-white' : 'text-[#135361] hover:text-white hover:bg-[#135361]'}`}
        >
          Anasayfa
        </Link>
        <Link
          to="/about"
          className={`block py-2 px-4 font-bold duration-300 rounded-lg text-center ${location.pathname === '/about' ? 'bg-[#ED762F] text-white' : 'text-[#135361] hover:text-white hover:bg-[#135361]'}`}
        >
          Hakkımızda
        </Link>
        <Link
          to="/acikpozisyonlar"
          className={`block py-2 px-4 font-bold duration-300  text-center rounded-full  ${location.pathname === '/acikpozisyonlar' ? 'bg-[#ED762F] text-white' : 'bg-secondary text-white hover:text-white hover:bg-primary'}`}
        >
          Aramıza Katıl
        </Link>
        <div className="">
        <Avatar
  alt="avatar"
  sx={{ width: 40, height: 40, backgroundColor: "#ED762F" }}
  src={userData?.profile_picture || undefined}
  {...(userData?.name ? stringAvatar(userData.name) : {})} // Only show text if user is logged in
  onClick={handleAvatarClick}
/>

          <Menu
            anchorEl={menuAnchorEl}
            open={Boolean(menuAnchorEl)}
            onClose={handleCloseMenu}
          >
            {userData ? (
              <div className='flex flex-col gap-1 p-2 items-start'>
                <button className='flex gap-2 items-center hover:bg-gray-100 px-4 w-full py-2 rounded' onClick={() => { navigate('/profile'); handleCloseMenu(); }}><User/>Profil</button>
                <button className='flex gap-2 items-center hover:bg-gray-100 px-4 w-full py-2 rounded' onClick={handleLogout}><LogOutIcon/>Çıkış Yap</button>
              </div>
            ) : (
              <div className='flex flex-col gap-1 p-2 items-start'>
                <button className='flex gap-2 items-center hover:bg-gray-100 w-full px-4 py-2 rounded' onClick={() => { navigate('/login'); handleCloseMenu(); }}><LogIn/>Giriş Yap</button>
                <button className='flex gap-2 items-center hover:bg-gray-100 w-full px-4 py-2 rounded' onClick={() => { navigate('/register'); handleCloseMenu(); }}><UserPlus/>Kayıt Ol</button>
              </div>
            )}
          </Menu>
        </div>
      </div>

      <button 
        onClick={toggleMenu} 
        className="ml-auto md:hidden text-[#ED762F] focus:outline-none"
      >
        <AlignJustify />
      </button>
      

      <div
        className={`fixed flex md:hidden flex-col top-0 h-screen right-0 w-64 bg-white shadow-lg z-20 transform transition-transform duration-300 ease-in-out ${isOpen ? 'translate-x-0' : 'translate-x-full'}`}
      >
        {isOpen && (
          <div className="transform transition-transform duration-400 ease-in-out p-3" onClick={toggleMenu}>
            <X />
          </div>
        )}
        <div className="flex flex-col gap-5 mt-0 m-4">
          <Link
            to="/"
            className={`block py-2 px-4 font-bold  duration-500 rounded-xl text-center ${location.pathname === '/' ? 'bg-[#ED762F] text-white' : 'text-[#135361] hover:text-[#ED762F] '}`}
          >
            Anasayfa
          </Link>
          <Link
            to="/about"
            className={`block py-2 px-4 font-bold duration-500 rounded-xl text-center ${location.pathname === '/about' ? 'bg-[#ED762F] text-white' : 'text-[#135361] hover:text-[#ED762F]'}`}
          >
            Hakkımızda
          </Link>
          <Link
            to="/acikpozisyonlar"
            className={`block py-2 px-4 font-bold duration-500 rounded-xl text-center ${location.pathname === '/acikpozisyonlar' ? 'bg-[#ED762F] text-white' : 'text-[#135361] hover:text-[#ED762F]'}`}
          >
            Aramıza Katıl
          </Link>
        </div>
        {isOpen && (
          <div className={`mt-auto mb-5 grid justify-center transform transition-transform duration-300 ease-in-out ${isOpen ? 'translate-x-0' : 'translate-x-full'}`}>
            <div className="flex gap-1">
        
                    <div className={`flexa items-center overflow-hidden transition-all ${isOpen ? "w-52" : "w-0"} `}>
                        <div className="flex flex-col">
                            <span className={`font-semibold text-primay text-nowrap`}>{userData ? `${userData.name} ${userData.surname}` : ''}</span>
                            <span className="text-xs text-primay opacity-60">{userData ? userData.email : ''}</span>
                        </div>
                    </div>

        
        </div>
            
          </div>
        )}
      </div>

      {isOpen && (
        <div 
          onClick={toggleMenu} 
          className="fixed inset-0 bg-black opacity-50 z-10 md:hidden"
        />
      )}
    </div>
  );
}

export default Header;
