// LoadingIndicator.js
import React from 'react';
import Logo from './Pages/Components/Logo.png'; // Import your logo

const LoadingIndicator = () => (
  <div className="flex items-center justify-center h-screen">
    <img src={Logo} alt="logo" className="h-32 w-auto animate-pulse-grow" />
  </div>
);

export default LoadingIndicator;
