import React from "react";
import { Routes, Route } from "react-router-dom";
import Sidebar, { SidebarItem } from "./Sidebar2.js";
import Dashboard from "./Dashboard.js";
import Users from "./Users.js";
import Fixedimages from "./Fixedimages.js"
import { AppWindow, Home, User } from "lucide-react";
import PageNotFound from "../Components/NotFound.js";
import Basvurular from "./Basvurular.js";
import AdminLayout from "../../Layout/AdminLayout.js";
import AddJobPage from "./AddJob.js";
import ApplicationsPage from "./AppPage.js";
import EditJobPage from "./EditJob.js";

const Admin = () => {
  return (
    <AdminLayout>
    <div className="flex h-screen">
    
      <Sidebar>
        <SidebarItem icon={<Home/>} text="AnaSayfa" to="/admin"/>
        <SidebarItem icon={<User/>} text="Kullanıcılar" to="/admin/users"/>
        <SidebarItem icon={<AppWindow/>} text="Başvurular" to="/admin/basvurular"/>

      </Sidebar>
      <div className="overflow-scroll w-full h-screen bg-gray-50 px-4 z-0">
        
        <Routes>
          <Route path="/" element={<Dashboard />} />
          <Route path="/users" element={<Users />} />
          <Route path="/fixedimages" element={<Fixedimages />} />
          <Route path="/basvurular" element={<Basvurular/>} />
          <Route path="/add-job" element={<AddJobPage/>} />
          <Route path="/jobs/:jobId/applications" element={<ApplicationsPage/>} />
          <Route path="/jobs/:jobId/edit" element={<EditJobPage/>} />


          <Route path="*" element={<PageNotFound/>} />
        </Routes>
        
      </div>
    </div>
    </AdminLayout>
  );
};

export default Admin;
