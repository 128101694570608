import React from 'react';
import { ChevronRight, ChevronLeft } from 'lucide-react';

const Pagination = ({ totalPages, currentPage, onPageChange }) => {
  const pageNumbers = [];

  if (totalPages <= 5) {
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(i);
    }
  } else {
    pageNumbers.push(1);
    const startPage = Math.max(2, currentPage - 2);
    const endPage = Math.min(totalPages - 1, currentPage + 2);
    if (startPage > 2) {
      pageNumbers.push('...');
    }
    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }
    if (endPage < totalPages - 1) {
      pageNumbers.push('...');
    }
    pageNumbers.push(totalPages);
  }

  return (
    <div className="flex justify-center items-center space-x-2 mt-8">
      <button
        onClick={() => onPageChange(currentPage - 1)}
        disabled={currentPage === 1}
        className="px-2 py-2"
      >
        <ChevronLeft className='text-gray-600'  size={18}/>
      </button>
      {pageNumbers.map((number, index) => (
        <div
          key={index}
          onClick={() => onPageChange(number)}
          className={`px-3 rounded-md border flex items-center cursor-pointer aspect-square ${number === currentPage ? 'bg-[#135361] text-white' : 'bg-white text-gray-700'}`}
          disabled={number === '...'}
        >
          {number}
        </div>
      ))}
      <button
        onClick={() => onPageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
        className="px-2 py-2 "
      >
        <ChevronRight className='text-gray-600' size={18}/>
      </button>
    </div>
  );
};

export default Pagination;
