import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import the Quill stylesheet
import { X } from "lucide-react";

const EditJobPage = () => {
  const { jobId } = useParams();
  const [title, setTitle] = useState("");
  const [department, setDepartment] = useState("");
  const [skills, setSkills] = useState([]);
  const [desc, setDesc] = useState("");
  const history = useNavigate();

  useEffect(() => {
    const fetchJob = async () => {
      try {
        const response = await axios.get(`https://ufkrender.onrender.com/jobs/${jobId}`);
        const job = response.data;
        setTitle(job.title);
        setDepartment(job.department);
        setSkills(job.skills);
        setDesc(job.desc);
      } catch (error) {
        console.error("Error fetching job:", error);
      }
    };

    fetchJob();
  }, [jobId]);

  const handleUpdate = async (e) => {
    e.preventDefault();
    try {
      await axios.patch(`https://ufkrender.onrender.com/jobs/${jobId}`, {
        title,
        department,
        skills,
        desc,
      });
      alert("Job updated successfully!");
      history("/admin/basvurular"); // Redirect back to jobs page
    } catch (error) {
      console.error("Error updating job:", error);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && e.target.value) {
      e.preventDefault();
      const newSkill = e.target.value.trim();
      if (!skills.includes(newSkill)) {
        setSkills([...skills, newSkill]);
      }
      e.target.value = ""; // Clear input field
    }
  };

  const removeSkill = (skillToRemove) => {
    setSkills(skills.filter(skill => skill !== skillToRemove));
  };

  return (
    <div className="edit-job-form">
      <h1>Edit Job</h1>
      <form onSubmit={handleUpdate}>
        <label>İlan Başlığı:</label>
        <input value={title} type="text" onChange={(e) => setTitle(e.target.value)} required className="border rounded px-2 py-2 w-72"/>
        
        <label>Departmanı:</label>
        <input value={department} type="text" onChange={(e) => setDepartment(e.target.value)} required className="border rounded px-2 py-2 w-72"/>
        
        <label>Yetenekler:</label>
        <div className="flex flex-wrap mb-4 items-center">
          {skills.map((skill, index) => (
            <div key={index} className="bg-blue-200 text-blue-800 rounded-full px-3 py-2 mr-2 my-1 flex items-center">
              {skill}
              <button type="button" className="ml-2 text-red-600" onClick={() => removeSkill(skill)}><X size={20}/></button>
            </div>
          ))}
          <input
            type="text"
            onKeyDown={handleKeyDown}
            placeholder="Yetenek Eklemek İçin Enter'a Basın"
            className="border rounded px-2 py-2 w-72"
          />
        </div>

        <label>Açıklama: " • " </label>
        <ReactQuill className="" value={desc} onChange={setDesc} />
        
        <button type="submit" className="px-3 py-2 mt-2 bg-primary rounded-lg font-semibold text-white ">Add Job</button>
      </form>
    </div>
  );
};

export default EditJobPage;
