import React, { useContext, useState, useEffect } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import axios from 'axios';
import { Avatar } from '@mui/material';
import { ChevronFirst, ChevronLast, MoreVertical } from "lucide-react";
import { useLoading } from '../../LoadingContext';
import Logo from "../Components/Logo.png";

function stringAvatar(name) {
    const nameParts = name.trim().split(' ');
    const initials = nameParts.length > 1 
        ? `${nameParts[0][0]}${nameParts[nameParts.length - 1][0]}` 
        : nameParts[0][0];

    return { children: initials.toUpperCase() };
}

const SidebarContext = React.createContext();

export default function Sidebar({ children }) {
    const [expanded, setExpanded] = useState(false);
    const [userData, setUserData] = useState(null);
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const { addLoadingSource, removeLoadingSource } = useLoading();

    const fetchUserData = async () => {
        addLoadingSource('sidebar'); // Start loading here

        try {
            const token = localStorage.getItem('token');
            if (!token) {
                navigate('/login');
                return;
            }

            const response = await axios.get('https://ufkrender.onrender.com/profile', {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            setUserData(response.data);
        } catch (err) {
            setError('Error fetching user data');
            if (err.response && err.response.status === 401) {
                navigate('/login');
            }
        } finally {
            removeLoadingSource('sidebar'); // Stop loading here
        }
    };

    useEffect(() => {
        fetchUserData();
    }, []);

    useEffect(() => {
        if (error) {
            navigate("/404");
        }
    }, [error, navigate]);

    if (!userData) return null; // Prevent rendering until userData is fetched

    return (
        <aside className="h-screen z-10">
            <nav className="h-full flex flex-col shadow-sm bg-white">
                <div className="py-3 pt-6 flex justify-between items-center px-6">
                    <img src={Logo} className={`overflow-hidden transition-all ${expanded ? "w-8" : "w-0"}`} alt="logo" />
                    <button onClick={() => setExpanded((curr) => !curr)} className="p-1.5 rounded-lg hover:bg-gray-100 text-secondary">
                        {expanded ? <ChevronFirst /> : <ChevronLast />}
                    </button>
                </div>

                <SidebarContext.Provider value={{ expanded }}>
                    <ul className={`flex-1 px-4`}>{children}</ul>
                </SidebarContext.Provider>

                <div className="flex px-6 py-4">
                    {userData.profile_picture ? (
                        <Avatar alt={userData.name} src={userData.profile_picture} sx={{ bgcolor: '#ED762F', color: '#fff' }} />
                    ) : (
                        <Avatar {...stringAvatar(userData.name)} />
                    )}

                    <div className={`flex justify-between items-center overflow-hidden transition-all ${expanded ? "w-52 ml-3" : "w-0"} `}>
                        <div className="flex flex-col">
                            <span className={`font-semibold text-primay text-nowrap`}>{userData ? `${userData.name} ${userData.surname}` : ''}</span>
                            <span className="text-xs text-primay opacity-60">{userData ? userData.email : ''}</span>
                        </div>
                        <MoreVertical size={20} className="text-primay cursor-pointer" />
                    </div>
                </div>
            </nav>
        </aside>
    );
}

export function SidebarItem({ icon, text, to }) {
    const { expanded } = useContext(SidebarContext);
    const location = useLocation();
    const isActive = location.pathname.endsWith(to.slice(1));

    return (
        <Link to={to}>
            <li className={`relative flex items-center py-3 px-4 mb-2 rounded-full cursor-pointer transition-colors group
                ${isActive ? "bg-gradient-to-tr from-gray-100 to-gray-50 text-primay" : "hover:bg-gray-100 text-secondary"}`}>
                {icon}
                <span className={`overflow-hidden transition-all font-semibold font-inter ${expanded ? "w-52 ml-4" : "w-0"}`}>{text}</span>

                {!expanded && (
                    <div className={`absolute left-full rounded-md px-2 py-1 ml-6 bg-gray-300 text-secondary text-sm invisible opacity-20 -translate-x-3 transition-all group-hover:visible group-hover:opacity-100 group-hover:translate-x-0`}>
                        {text}
                    </div>
                )}
            </li>
        </Link>
    );
}
