import React, { useState } from 'react';
import axios from 'axios';
import ImgSelect from './Img'; // Ya da dosya yolu

const LogoSelectPage = ({ onUpdateLogo }) => {
  const [selectedLogo, setSelectedLogo] = useState(null);

  const handleLogoSelect = (logo) => {
    setSelectedLogo(logo);
  };

  const handleLogoUpdate = async () => {
    if (!selectedLogo) {
      console.error('No image selected for logo.');
      return;
    }

    try {
      await axios.post('/api/updateLogo', { logoURL: selectedLogo.path });
      console.log('Logo updated successfully.');
    } catch (error) {
      console.error('Error updating logo:', error);
    }
  };

  return (
    <div>
      <h1>Logo Seçme Sayfası</h1>
      <ImgSelect onSelect={handleLogoSelect} />
      {/* Burada seçilen logoyu kullanabilirsiniz */}
      {selectedLogo && (
        <div>
          <h2>Seçilen Logo:</h2>
          <img src={`http://192.168.68.104:3001/${selectedLogo.path}`} alt={selectedLogo.filename} />
        </div>
      )}
      <button onClick={handleLogoUpdate}>Seçili Resmi Logo Yap</button>
    </div>
  );
};

export default LogoSelectPage;
