import React, { useState, useEffect } from 'react';
import JobCard from './JobCard';
import Pagination from './Pagination';
import FilterButton from './Filter';
import axios from 'axios';
import { useLoading } from '../../LoadingContext';

const HomePage = () => {
  const itemsPerPage = 9;
  const [currentPage, setCurrentPage] = useState(1);
  const [jobs, setJobs] = useState([]);
  const [filteredJobs, setFilteredJobs] = useState([]);
  const { addLoadingSource, removeLoadingSource } = useLoading();

  useEffect(() => {
    const fetchJobs = async () => {
      addLoadingSource('sidebar');
      try {
        const response = await axios.get('https://ufkrender.onrender.com/jobs');
        const activeJobs = response.data.filter(job => job.active); // Sadece aktif iş ilanlarını filtrele
        setJobs(activeJobs);
        setFilteredJobs(activeJobs);
        removeLoadingSource('sidebar'); 
      } catch (error) {
        console.error('Error fetching jobs:', error);
      }
    };
    fetchJobs();
  }, []);

  const totalPages = Math.ceil(filteredJobs.length / itemsPerPage);
  const currentJobs = filteredJobs.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const onPageChange = (pageNumber) => {
    if (typeof pageNumber === 'number') {
      setCurrentPage(pageNumber);
      window.scrollTo(0, 0);
    }
  };

  const handleFilter = (selectedDepartments) => {
    if (selectedDepartments.length > 0) {
      const filtered = jobs.filter((job) =>
        selectedDepartments.includes(job.department)
      );
      setFilteredJobs(filtered);
      setCurrentPage(1);
    } else {
      setFilteredJobs(jobs);
    }
  };

  const handleClear = () => {
    setFilteredJobs(jobs);
    setCurrentPage(1);
  };

  if (!jobs) return null;
  return (
    <div className="min-h-screen flex flex-col relative px-2">
      <div className='z-0 flex-grow'>
        <div className="container my-8 flex-grow lg:px-0 px-12 ">
          <h1 className="text-5xl font-sans font-bold text-center mt-16">Aramıza Katıl!</h1>
          <p className="text-xl text-center text-gray-600 mb-16 mt-5">
            Geleceğin teknolojilerini birlikte geliştirelim.
          </p>
          <div className="flex sm:justify-start justify-center">
            <FilterButton onFilter={handleFilter} onClear={handleClear} />
          </div>
          <div className="mt-4 mb-8 ml-1 text-sm font-sans font-semibold text-md">
            {filteredJobs.length} açık ilan
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6 -z-10">
            {currentJobs.map((job, index) => (
              <div key={index} className="min-h-80 flex">
                <JobCard job={job} />
              </div>
            ))}
          </div>
          <Pagination
            totalPages={totalPages}
            currentPage={currentPage}
            onPageChange={onPageChange}
          />
        </div>
      </div>
    </div>
  );
};

export default HomePage;
