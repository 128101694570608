import React from 'react';
import { NotepadText, Share2 } from 'lucide-react';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom

const JobCard = ({ job }) => {

  const handleShare = () => {
    if (navigator.share) {
      navigator.share({
        text: `UFK Teknoloji Takımı \b${job.department} departmanı için \b${job.title} alımı yapıyormuş duydun mu?`,
        url: `${window.location.origin}/jobs/${job.id}`
      })
      .catch((error) => console.error('Error sharing job:', error));
    } else {
      // Fallback for browsers that do not support the Web Share API
      alert('Share feature is not supported on this browser');
    }
  };

  return (
    <div className="rounded-2xl p-6 bg-white shadow-md duration-500 flex flex-col h-full w-full">
      <div className='flex items-center justify-between'>
        
        {/* Job Title and Department */}
        <div className='flex flex-col'>
          <p className="text-xl font-semibold mb-1 text-wrap">{job.title}</p>
          <p className="text-sm text-gray-500">{job.department}</p>
        </div>
        
        {/* Share Icon */}
        <div 
          className='border border-gray-300 rounded-full p-2 cursor-pointer hover:bg-gray-100'
          onClick={handleShare} // Attach the click handler
        >
          <Share2 size={18} className="text-gray-600" />
        </div>
      </div>

      {/* Skills Section */}
      <div className="mt-4 flex flex-wrap gap-2">
        {job.skills.map((skill, index) => (
          <span 
            key={index} 
            className="bg-gray-100 text-[#445757] rounded-lg px-3 py-1 text-xs font-medium">
            {skill}
          </span>
        ))}
      </div>

      {/* Action Buttons */}
      <div className="mt-auto flex justify-between items-center gap-4">
        
        {/* Apply Button */}
        <Link to={`/jobs/basvuru/${job.id}`}  className="flex items-center justify-center gap-2 w-full h-10 bg-[#135361] text-white text-sm font-medium rounded-lg hover:bg-[#0f4b53] duration-300">
          <NotepadText size={18} />
          Başvuru Yap
        </Link>
        
        {/* View Details Button */}
        {/* Use id instead of _id */}
        <Link to={`/jobs/${job.id}`} className="w-full h-10 bg-gray-100 text-black text-sm font-medium rounded-lg hover:bg-gray-200 duration-300 flex items-center justify-center">
          İncele
        </Link>
      </div>
    </div>
  );
};

export default JobCard;
