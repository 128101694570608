import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import Logo from "../Components/Logo.png";
import { NotepadText, Share2 } from 'lucide-react';
import { FaDiscord } from 'react-icons/fa';
import { WhatsApp } from '@mui/icons-material';

function JobPage() {
  const { jobId } = useParams();  // Get jobId from the route params
  const [job, setJob] = useState(null);  // State to hold job details
  const [loading, setLoading] = useState(true);  // State for loading
  const [error, setError] = useState(null);  // State for error

  // Fetch job details when component mounts
  useEffect(() => {
    const fetchJobDetails = async () => {
      try {
        const response = await axios.get(`https://ufkrender.onrender.com/jobs/${jobId}`);
        setJob(response.data);
        setLoading(false);
      } catch (error) {
        setError("İş ilanı bulunamadı");
        setLoading(false);
      }
    };
    fetchJobDetails();
  }, [jobId]);

  const handleShare = () => {
    if (navigator.share) {
      navigator.share({
        text: `UFK Teknoloji Takımı \b${job.department} departmanı için \b${job.title} alımı yapıyormuş duydun mu?`,
        url: `${window.location.origin}/jobs/${job.id}`
      })
      .catch((error) => console.error('Error sharing job:', error));
    } else {
      // Fallback for browsers that do not support the Web Share API
      alert('Share feature is not supported on this browser');
    }
  };

  // Function to return description based on the department
  const getDepartmentDescription = (department) => {
    switch (department) {
      case 'Sosyal Medya':
        return 'Sosyal Medya ekibi, markaların dijital ortamlarda varlıklarını sürdürebilmesi ve sosyal medya stratejileri oluşturulması üzerine çalışır.';
      case 'Proje / Mekanik':
        return 'Proje ekibi, kapsamlı planlama ve uygulama süreçlerini yöneterek projelerin zamanında ve hedeflere uygun şekilde tamamlanmasını sağlar.';
      case 'Proje / Yazılım Geliştirme':
        return 'Proje ekibi, kapsamlı planlama ve uygulama süreçlerini yöneterek projelerin zamanında ve hedeflere uygun şekilde tamamlanmasını sağlar.';
      case 'Proje / Aviynik Sistemler':
        return 'Proje ekibi, kapsamlı planlama ve uygulama süreçlerini yöneterek projelerin zamanında ve hedeflere uygun şekilde tamamlanmasını sağlar.';
      case 'Sponsorluk':
        return 'Sponsorluk ekibi, projelerin finansal ve kaynak ihtiyaçlarını belirleyerek, uygun sponsorları bulmak ve onlarla ilişkileri yönetmekle sorumludur.';
      case 'Organizasyon':
        return 'Organizasyon ekibi, projelerin etkin bir şekilde planlanmasını ve yürütülmesini sağlamak için gerekli süreçleri yönetir ve koordine eder.';     
      default:
        return 'Bu departman hakkında daha fazla bilgi bulunmamaktadır.';
    }
  };

  if (loading) {
    return <div className="flex items-center justify-center h-screen"><img src={Logo} alt="logo" className='h-32 w-auto animate-pulse-grow' /></div>;
  }

  if (error) {
    return <div className="flex items-center justify-center h-screen">{error}</div>;
  }

  if (!job) {
    return <div className="flex items-center justify-center h-screen">İlan bulunamadı.</div>;
  }

  return (
    <div className="min-h-screen flex flex-col">
      <div className="flex-grow z-0">
        <div className="container mx-auto px-4">
          <div className="flex justify-between items-center">
            {/* Title and Action Buttons */}
            <h1 className="text-4xl font-bold px-4 py-8">{job.title}</h1>
            <div className="flex gap-6 items-center">
              <Share2 className="cursor-pointer hover:opacity-80" onClick={handleShare} size={25} />
              <Link to={`/jobs/basvuru/${jobId}`} className="flex items-center justify-center gap-2 px-4 py-2 bg-[#135361] text-white font-medium rounded-lg hover:bg-[#0f4b53] transition duration-300">
                <NotepadText size={18} />
                Başvuru Yap
              </Link>
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
            {/* Job Description Section */}
            <div className="col-span-3 row-span-2 bg-white p-6 rounded-lg shadow-md">
              <h2 className="text-2xl font-semibold mb-4">İlan Açıklaması</h2>
              <div className="text-gray-700 leading-relaxed text-lg list-disc" dangerouslySetInnerHTML={{ __html: job.desc }} />
            </div>
            
            {/* Department Section */}
            <div className="bg-white p-6 rounded-lg shadow-md col-span-3 md:col-span-1">
              <h2 className="text-2xl font-semibold mb-4">Departman</h2>
              <p className="text-lg font-medium text-gray-600">{job.department}</p>
              <p className="mt-4 text-gray-500">{getDepartmentDescription(job.department)}</p> {/* Conditionally rendered text */}
            </div>

            <div className="bg-ufkblue p-6 rounded-lg shadow-md col-span-3 md:col-span-1 items-center">
              <p className="font-objr leading-relaxed">Sorularınız için iletişim kanallarımızdan irtibata geçebilirsiniz.</p>
              <div className="flex gap-5 items-center justify-center mt-5">
                <Link to="https://discord.gg/MzBvGKXQ9J" className="p-3 rounded-full bg-secondary hover:bg-primary duration-300">
                  <FaDiscord className="text-white text-2xl" />
                </Link>
                <Link to="https://chat.whatsapp.com/EWVC77LjU4N3iEPQlcWoHB" className="p-3 rounded-full bg-secondary hover:bg-primary duration-300">
                  <WhatsApp className="text-white text-2xl" />
                </Link>
              </div>
            </div>
          </div>

          {/* Skills Section */}
          <div className="mt-8 bg-white p-6 rounded-lg shadow-md">
            <h2 className="text-2xl font-semibold mb-4">Yetenekler</h2>
            <div className="flex flex-wrap gap-3">
              {job.skills.map((skill, index) => (
                <span key={index} className="bg-gray-100 text-gray-700 px-4 py-2 rounded-lg shadow-sm text-md">
                  {skill}
                </span>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default JobPage;
