import React, { useState, useEffect } from 'react';

function Duyuru({ isOpenByDefault, trigger, children, overflow }) {
  const [isOpen, setIsOpen] = useState(isOpenByDefault);

  useEffect(() => {
    
    if (trigger) {
      setIsOpen(true);
    } 
  }, [trigger]);

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleBackdropClick = (e) => {
    if (e.target.id === 'backdrop') {
      handleClose();
    }
  };

  if (!isOpen) return null;

  return (
    <div
      id="backdrop"
      className={`fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50`}
      onClick={handleBackdropClick}
    >
      <div
        className={`bg-white rounded-2xl shadow-lg w-full max-w-md h-auto max-h-[90vh] p-8 relative flex flex-col items-center`}
        onClick={(e) => e.stopPropagation()}
      >
        <button
          className="absolute top-2 right-2 text-gray-400 hover:text-gray-600"
          onClick={handleClose}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>

        <div className={`w-full h-full rounded-lg ${overflow ? "overflow-auto" : ""}`}>
          {children}
        </div>
      </div>
    </div>
  );
}

export default Duyuru;
