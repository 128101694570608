import { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; 
import { X } from "lucide-react";

const AddJobPage = () => {
  const [title, setTitle] = useState("");
  const [department, setDepartment] = useState("");
  const [skills, setSkills] = useState([]);
  const [desc, setDesc] = useState("");
  const history = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post("https://ufkrender.onrender.com/add-job", {
        title,
        department,
        skills,
        desc,
      });
      alert(response.data.message);
      history("/admin/basvurular");  // Redirect back to jobs page
    } catch (error) {
      console.error("Error adding job:", error);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && e.target.value) {
      e.preventDefault();
      const newSkill = e.target.value.trim();
      if (!skills.includes(newSkill)) {
        setSkills([...skills, newSkill]);
      }
      e.target.value = ""; // Clear input field
    }
  };

  const removeSkill = (skillToRemove) => {
    setSkills(skills.filter(skill => skill !== skillToRemove));
  };

  return (
    <div className="add-job-form">
      <h1>Add New Job</h1>
      <form onSubmit={handleSubmit}>
        <label>İlan Başlığı:</label>
        <input value={title} type="text" onChange={(e) => setTitle(e.target.value)} required className="border rounded px-2 py-2 w-72"/>
        
        <label>Departmanı:</label>
        <input value={department} type="text" onChange={(e) => setDepartment(e.target.value)} required className="border rounded px-2 py-2 w-72"/>
        
        <label>Yetenekler:</label>
        <div className="flex flex-wrap mb-4 items-center">
          {skills.map((skill, index) => (
            <div key={index} className="bg-blue-200 text-blue-800 rounded-full px-3 py-2 mr-2 my-1 flex items-center">
              {skill}
              <button type="button" className="ml-2 text-red-600" onClick={() => removeSkill(skill)}><X size={20}/></button>
            </div>
          ))}
          <input
            type="text"
            onKeyDown={handleKeyDown}
            placeholder="Yetenek Eklemek İçin Enter'a Basın"
            className="border rounded px-2 py-2 w-72"
          />
        </div>

        <label>Açıklama: " • " </label>
        <ReactQuill className="" value={desc} onChange={setDesc} />
        
        <button type="submit" className="px-3 py-2 mt-2 bg-primary rounded-lg font-semibold text-white ">Add Job</button>
      </form>
    </div>
  );
};

export default AddJobPage;
