import React from 'react';
import { Instagram, LinkedIn } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import XIcon from '@mui/icons-material/X';

function Footer() {
  return (
    <div className="f-footer-small">
      <div className="f-container-large">
        <div className="f-footer-bottom">
          <div className="f-footer-brand">
            <div className="f-footer-line" />
            <p className="f-footer-detail">UFK Teknoloji Takımı 2024.</p>
            <p className="f-footer-detail -mt-0.5 ml-2">©</p>
          </div>
          <div className="f-footer-group">
            <div className="f-footer-social-row-large">
              <div className="f-footer-social-group">
                <Link
                  to="https://instagram.com/ufktakimi"
                  className="f-footer-social w-inline-block"
                >
                  <div className="f-icon-regular w-embed">
                    <Instagram loading="lazy" width={27} className="f-icon-regular" />
                  </div>
                </Link>
                <Link
                  to="https://x.com/ufktakimi"
                  className="f-footer-social w-inline-block"
                >
                  <div className="f-icon-regular w-embed">
                    <XIcon loading="lazy" width={27} className="f-icon-regular" />
                  </div>
                </Link>
                <Link
                  to="https://linkedin.com/company/ufktakimi"
                  className="f-footer-social w-inline-block hover:text-white"
                >
                  <LinkedIn loading="lazy" width={27} className="f-icon-regular" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
