import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, Dialog, DialogContent, Typography } from '@mui/material';
import ErrorBox from './ErrorBox'; // ErrorBox bileşenini dahil et

const ImgSelect = ({ onSelect }) => {
  const [images, setImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState("");
  const [showDialog, setShowDialog] = useState(false);
  const [hoveredImage, setHoveredImage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(""); // Hata mesajı durumu

  const fetchImages = async () => {
    try {
      const response = await axios.get('/api/getAllImages');
      setImages(response.data);
    } catch (error) {
      console.error('Error fetching images:', error);
    }
  };

  useEffect(() => {
    fetchImages();
  }, []);

  const handleSelectImage = (image) => {
    setSelectedImage(image);
  };

  const handleDeleteImage = async () => {
    if (selectedImage) {
      try {
        await axios.delete(`/api/deleteImage/${selectedImage._id}`);
        setImages(images.filter(img => img._id !== selectedImage._id));
        setSelectedImage("");
      } catch (error) {
        console.error('Error deleting image:', error);
      }
    } else {
      setErrorMessage("Lütfen bir resim seçin."); // Resim seçilmediğinde hata mesajını ayarla
    }
  };

  const handleCloseErrorBox = () => {
    setErrorMessage(""); // Hata mesajını kapat
  };

  const handleSelect = () => {
    if (selectedImage) {
      onSelect(selectedImage);
      setShowDialog(false);
    } else {
      setErrorMessage("Lütfen bir resim seçin."); // Seçili resim yoksa hata mesajını ayarla
    }
  };

  return (
    <div>
      <h1>Admin Sayfası</h1>
      <Button variant="contained" onClick={() => setShowDialog(true)}>Resimleri Göster</Button>
      <Dialog open={showDialog} onClose={() => setShowDialog(false)} maxWidth="xl">
        <DialogContent style={{ height: '70vh' }} className='flex gap-4'>
          {/* Hata mesajını göster */}
          <ErrorBox message={errorMessage} onClose={handleCloseErrorBox} />

          <div className="overflow-y-auto w-7/12 md:w-8/12 p-2">
            <div className="grid grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-4">
              {images.map((image) => (
                <div
                  key={image._id}
                  className="relative overflow-hidden"
                  onMouseEnter={() => setHoveredImage(image)}
                  onMouseLeave={() => setHoveredImage(null)}
                >
                  <img
                    src={`http://localhost:3001/${image.path}`}
                    onClick={() => handleSelectImage(image)}
                    className={`cursor-pointer border-2 bg-gray-200  max-w-full rounded-lg aspect-square object-cover ${
                      selectedImage === image ? "border-blue-500" : "border-gray-300"
                    }`}
                    alt={image.filename}
                  />
                  <p className={`absolute bottom-2 truncate text-sm text-white z-10 bg-black bg-opacity-50 py-1 max-w-full rounded ${hoveredImage === image ? "" : "hidden"}`}>
                    {image.filename}
                  </p>
                </div>
              ))}
            </div>
          </div>

          <div className="overflow-y-auto w-5/12 md:w-4/12 p-2 max-h-full">
            <div className="flex">
              <Typography variant="h5">Resim Önizleme</Typography>
            </div>
            <div className="border border-gray-300 bg-gray-200 aspect-square flex justify-center items-center ">
              {selectedImage ? (
                <img
                  src={`http://localhost:3001/${selectedImage.path}`}
                  alt={selectedImage.filename}
                  className="max-h-full"
                />
              ) : (
                <Typography variant="body1">Resim seçilmedi</Typography>
              )}
            </div>
            <div>
              <Typography variant="h6">Resim Detayları</Typography>
              {selectedImage && (
                <div>
                  <p>Resim Adı: {selectedImage.filename}</p>
                  <p>Resim Boyutu: {selectedImage.size}</p>
                </div>
              )}
              <Button variant="contained" color="error" onClick={handleDeleteImage}>Resmi Sil</Button>
              <Button variant="contained" onClick={handleSelect}>Seç</Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ImgSelect;
