import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Mainpage from "./Pages/Mainpage";
import Hakkimizda from "./Pages/Hakkimizda";
import Contact from "./Pages/Contact";

import RegisterPage from "./Pages/Auth/RegisterPage";
import LoginPage from "./Pages/Auth/Login";
import ProfilePage from "./Pages/Auth/Profile";
import VerifyEmailPage from "./Pages/Auth/VerifyEmailPage";
import PageNotFound from "./Pages/Components/NotFound";
import AcikPozisyonlar from "./Pages/AcikPozisyon/AcikPozisyonlar";
import JobPage from "./Pages/AcikPozisyon/JobPage";
import Admin from "./Pages/Admin/Admin";
import BasvuruFormu from "./Pages/AcikPozisyon/BasvuruFormu";


import MainLayout from "./Layout/MainLayout";
import { LoadingProvider } from "./LoadingContext";

function App() {
  return (
    <LoadingProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<MainLayout><Mainpage /></MainLayout>} />
          <Route path="/about" element={<MainLayout><Hakkimizda /></MainLayout>} />
          <Route path="/contuctus" element={<MainLayout><Contact /></MainLayout>} />
          <Route path="/register" element={<RegisterPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/profile" element={<MainLayout><ProfilePage /></MainLayout>} />
          <Route path="/verify-email" element={<VerifyEmailPage />} />
          <Route path="/acikpozisyonlar" element={<MainLayout classname="bg-[#F7F7F8]"><AcikPozisyonlar /></MainLayout>} />
          <Route path="/jobs/basvuru/:jobId" element={<BasvuruFormu />} />
          <Route path="/jobs/:jobId" element={<MainLayout classname="bg-[#F7F7F8]"><JobPage /></MainLayout>} />



          <Route
            path="/admin/*"
            element={
                <Admin />
            }
          />

          
          <Route path="*" element={<MainLayout classname="bg-[#F7F7F8]"><PageNotFound /></MainLayout>} />
        </Routes>
      </BrowserRouter>
    </LoadingProvider>
  );
}

export default App;
