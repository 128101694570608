import React from 'react'
import { Link } from 'react-router-dom'
import p404 from "./404.png"

const PageNotFound = () => {
    return (
        <div class="h-screen  flex items-center">
            <div class="container flex flex-col md:flex-row items-center justify-between px-5 text-gray-700">
                    <div class="w-full  mx-8">
                    <img src={p404} alt="404" className=' max-w-md pb-12' />
                    <p class="text-2xl md:text-3xl font-light leading-normal mb-8">
                        Üzgünüz, aradığınız sayfa bulunamamakta!!
                    </p>
                    
                    <Link to="/" className="px-5 font-bold inline py-3 text-sm  leading-5 shadow-2xl text-white transition-all duration-400 border border-transparent rounded-lg focus:outline-none bg-[#ED762F] active:bg-[#135361] hover:bg-[#135361]">
                    Anasayfa</Link>
            </div>
                
                
            
            </div>
        </div>
    
    )
}

export default PageNotFound