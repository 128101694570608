import React, { createContext, useContext, useState } from 'react';

const LoadingContext = createContext();

export const LoadingProvider = ({ children }) => {
  const [loadingSources, setLoadingSources] = useState(new Set());

  const addLoadingSource = (source) => {
    setLoadingSources((prev) => new Set(prev).add(source));
  };

  const removeLoadingSource = (source) => {
    setLoadingSources((prev) => {
      const updated = new Set(prev);
      updated.delete(source);
      return updated;
    });
  };

  return (
    <LoadingContext.Provider value={{ loadingSources, addLoadingSource, removeLoadingSource }}>
      {children}
    </LoadingContext.Provider>
  );
};

export const useLoading = () => useContext(LoadingContext);
