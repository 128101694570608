import React from 'react';
import { useLoading } from '../LoadingContext';
import LoadingIndicator from '../LoadingIndicator';

const AdminLayout = ({ children, classname }) => {
  const { loadingSources } = useLoading();
  const isLoading = loadingSources.size > 0;

  return (
    <div className={`${classname}`}>
      {isLoading && <LoadingIndicator />}
      <div className={isLoading ? 'hidden' : ''}>
      <main>{children}</main>
      </div>
    </div>
  );
};

export default AdminLayout;
