import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Alert } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import Logo from '../Components/Logo.png';

function LoginPage() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    // Check if user is already logged in
    const token = localStorage.getItem('token');
    if (token) {
      // Verify token (optional)
      axios.get('https://ufkrender.onrender.com/verify-token', { headers: { Authorization: `Bearer ${token}` } })
        .then(() => {
          navigate('/profile');
        })
        .catch(() => {
          localStorage.removeItem('token');
        });
    }
  }, [navigate]);

  
  const onSubmit = async (e) => {
    e.preventDefault();
    try {
        const response = await axios.post('https://ufkrender.onrender.com/login', { email, password });

        if (response.status === 200) {
            const token = response.data.token;
            localStorage.setItem('token', token);
            navigate('/profile'); // Başarıyla giriş yaptıktan sonra profil sayfasına yönlendir
        } else {
            throw new Error('Login failed');
        }
    } catch (error) {
        
        if (error.response && error.response.status === 403) {
          setError('Lütfen e-posta adresinizi doğrulayın.'); // E-posta doğrulama hatası
        } else if (error.response && error.response.status === 401) {
          setError('Kullanıcı adı veya şifre yanlış!'); // Geçersiz kimlik bilgileri
        } else {
          setError('Beklenmedik bir durum oluştu. Lütfen daha sonra tekrar deneyin.'); // Diğer hata durumları
        }
    }
};








  return (
    <div className="h-screen">
      <div className="fixed p-5 flex items-center w-full">
      <Link to="/" className='flex gap-3 items-center'>
        <img src={Logo} alt="Logo" className='h-10 ' />
        <div className="flex gap-1 mt-2">
          <p className='text-[#ED762F] font-black'>UFK</p>
          <p className='text-[#135361] font-semibold'>Teknoloji Takımı</p>
        </div>
        </Link>
        <div className="ml-auto">
          <Link to="/register">
            <button className='bg-[#ED762F] text-white font-bold py-2 px-4 font-inter text-sm rounded-xl hover:bg-secondary duration-300'>Kayıt Ol</button>
          </Link>
        </div>
      
      </div>
      {error && <Alert severity="error" className='fixed left-1/2 -translate-x-1/2 w-1/4 justify-center '>{error} <div className="fixed right-2 top-1 cursor-pointer" onClick={()=>setError(null)}>X</div></Alert>}
      <div className="flex items-center h-full p-auto">
        <div className="grid gap-5 m-auto rounded-3xl py-12">
          <h1 className='text-6xl w-96 font-inter font-bold'>Oturum Aç</h1>
          <div className='h-0.5 w-full bg-gray-100'/>
          <p className='text-sm font-semibold mr-auto'>Kullanıcı bilgilerinizi girin.</p>
          <form onSubmit={onSubmit} className="grid gap-3">
            <input type="text" placeholder="E-mail" value={email} onChange={(e) => setEmail(e.target.value)} className="bg-gray-100 focus:outline-none w-full h-10 rounded-lg p-2" required />
            <input type="password" placeholder="Şifre" value={password} onChange={(e) => setPassword(e.target.value)} className="bg-gray-100 focus:outline-none w-full h-10 rounded-lg p-2" required />
            
            <button type="submit" className="bg-[#135361] font-bold text-white w-full h-10 font-inter rounded-lg">Giriş Yap</button>
          </form>
          <div className="flex items-center justify-center gap-2">
          <p className='text-gray-400 '>Hesabın yok mu?</p>
          <Link to="/register">
          <p className='font-bold text-primary duration-300 hover:text-secondary'>Kayıt Ol</p>
          </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoginPage;
