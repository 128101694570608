import React, { useEffect, useState } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Logo from "../Components/Logo.png";
import { ArrowBack } from '@mui/icons-material';
import Duyuru from '../Components/Duyuru';

export default function SimpleComponent() {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    okul: '',
    bolum: '',
    sinif: '',
    linkedin: '',
    agreeToTerms: false,
    durum:"1",
  });


  const [showModal, setShowModal] = useState(false); 
  const token = localStorage.getItem('token');
  const { jobId } = useParams(); // Get jobId from the route params
  const [job, setJob] = useState(null); // State to hold job details
  const [loading, setLoading] = useState(true); // State for loading
  const [error, setError] = useState(null); // State for error
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const formDataToSend = new FormData();
    formDataToSend.append('firstName', formData.firstName);
    formDataToSend.append('lastName', formData.lastName);
    formDataToSend.append('email', formData.email);
    formDataToSend.append('phone', formData.phone);
    formDataToSend.append('okul', formData.okul);
    formDataToSend.append('bolum', formData.bolum);
    formDataToSend.append('sinif', formData.sinif);
    formDataToSend.append('linkedin', formData.linkedin);
    formDataToSend.append('durum', formData.durum);
    formDataToSend.append('jobId', jobId); // Attach the jobId
  
    try {
      const response = await axios.post('https://ufkrender.onrender.com/apply-job', formDataToSend, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        
      });
      
      if (response.status === 201) {
        setLoading(false);
        alert('Başvurunuz Tamamlanmıştır');
        navigate("/acikpozisyonlar")

      }
    } catch (error) {
      console.error('Bir hata oluştu lütfen tekrar deneyin.');
    }
  };
  

  // Fetch job details and user info when component mounts
  useEffect(() => {
    const fetchJobDetails = async () => {
      try {
        const response = await axios.get(`https://ufkrender.onrender.com/jobs/${jobId}`);
        setJob(response.data);
        setLoading(false)
      } catch (error) {
        setError("İş ilanı bulunamadı");
      } 
    };

    const fetchUser = async () => {
      try {
        const response = await axios.get('https://ufkrender.onrender.com/profile', {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });
        setFormData(prev => ({
          ...prev,
          firstName: response.data.name || '',
          lastName: response.data.surname || '',
          email: response.data.email || '',
        }));
      } catch (err) {
        
      } 
    };

    fetchUser();
    fetchJobDetails();
  }, [jobId, token,navigate]);

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <img src={Logo} alt="logo" className='h-32 w-auto animate-pulse-grow' />
      </div>
    );
  }

  if (error) {
    return <p className="text-red-500">{error}</p>;
  }

  return (
    <div className="flex flex-col lg:flex-row min-h-screen bg-gray-50">

<Duyuru isOpenByDefault={false} trigger={showModal} overflow={true} >
      <div className="">
      <h1 className="text-2xl font-bold mb-4">Kullanım Koşulları</h1>
      <h2 className="text-xl font-semibold mb-2">1. Genel Hükümler</h2>
      <p className="mb-4">
        Bu web sitesini ziyaret eden veya kullanan tüm kullanıcılar, aşağıda belirtilen koşulları kabul etmiş sayılır. UFK Teknoloji, önceden haber vermeksizin bu kullanım koşullarında değişiklik yapma hakkını saklı tutar. Güncellenmiş koşullar, sitede yayınlandığı tarihten itibaren geçerli olacaktır.
      </p>
      <h2 className="text-xl font-semibold mb-2">2. Fikri Mülkiyet Hakları</h2>
      <p className="mb-4">
        Web sitemizde yer alan tüm içerik, görseller, logolar, grafikler ve diğer materyaller, telif hakkı ve diğer fikri mülkiyet hakları kapsamında korunmaktadır. İzinsiz kopyalanması, dağıtılması veya kullanılması yasaktır.
      </p>
      <h2 className="text-xl font-semibold mb-2">3. Kullanıcı Sorumluluğu</h2>
      <p className="mb-4">
        Kullanıcılar, web sitesini hukuka aykırı bir şekilde kullanmamayı, üçüncü şahısların haklarına zarar vermemeyi ve UFK Teknoloji'ye ait sistemlere zarar verecek eylemlerden kaçınmayı kabul ederler.
      </p>
      <h2 className="text-xl font-semibold mb-2">4. Hizmetlerin Kesintisizliği</h2>
      <p className="mb-4">
        UFK Teknoloji, web sitesinin sürekli olarak erişilebilir olmasını sağlamak için elinden geleni yapar, ancak teknik arızalar, bakım çalışmaları veya başka nedenlerle hizmetlerde kesintiler yaşanabilir. UFK Teknoloji, bu tür durumlarda sorumluluk kabul etmez.
      </p>
      <h2 className="text-xl font-semibold mb-2">5. Üçüncü Taraf Bağlantıları</h2>
      <p className="mb-4">
        Web sitemizde üçüncü taraf web sitelerine yönlendiren bağlantılar bulunabilir. Bu sitelerin içeriklerinden veya gizlilik uygulamalarından UFK Teknoloji sorumlu değildir.
      </p>

      <h1 className="text-2xl font-bold mt-8 mb-4">Gizlilik Politikası</h1>
      <h2 className="text-xl font-semibold mb-2">1. Toplanan Bilgiler</h2>
      <p className="mb-4">
        UFK Teknoloji, web sitesi üzerinden yalnızca e-posta adresi ve telefon numarası gibi kullanıcıların iletişim bilgilerini toplar. Bu bilgiler, yalnızca hesap doğrulama ve kullanıcıyla iletişim amacıyla kullanılır. Ticari amaçlı herhangi bir veri işleme yapılmaz.
      </p>
      <h2 className="text-xl font-semibold mb-2">2. Verilerin Kullanımı</h2>
      <p className="mb-4">
        Topladığımız kişisel veriler, kullanıcılara hizmet sunabilmek ve hesap işlemlerini doğrulamak amacıyla kullanılır. Verileriniz, izniniz olmadan üçüncü kişilerle paylaşılmayacaktır.
      </p>
      <h2 className="text-xl font-semibold mb-2">3. Verilerin Korunması</h2>
      <p className="mb-4">
        Kişisel verilerinizin güvenliği için gerekli tüm teknik ve idari önlemler alınmaktadır. Bilgileriniz, yetkisiz erişime, kaybolmaya veya kötüye kullanıma karşı korunmaktadır.
      </p>
      <h2 className="text-xl font-semibold mb-2">4. Kullanıcı Hakları</h2>
      <p className="mb-4">
        6698 sayılı Kişisel Verilerin Korunması Kanunu kapsamında, kullanıcılar topladıkları verilerin silinmesini, düzeltilmesini veya erişilmesini talep edebilirler. Bu haklarınızı kullanmak için bizimle iletişime geçebilirsiniz.
      </p>
      <h2 className="text-xl font-semibold mb-2">5. Çerezler</h2>
      <p className="mb-4">
        Web sitemiz, kullanıcı deneyimini iyileştirmek için çerezler kullanabilir. Çerezler, kullanıcıların site üzerindeki davranışlarını izlemek veya web trafiğini analiz etmek için kullanılmaktadır.
      </p>
      <h2 className="text-xl font-semibold mb-2">6. Verilerin Saklanma Süresi</h2>
      <p className="mb-4">
        Toplanan kişisel veriler, işlenme amacının gerektirdiği süre boyunca veya yasal gereklilikler çerçevesinde saklanacaktır. Verileriniz, işlenme amacı sona erdiğinde güvenli bir şekilde imha edilecektir.
      </p>
    </div>
      </Duyuru>

      <div className="w-full lg:w-2/3 p-8">
        <header className="mb-8">
          <div className="flex flex-wrap gap-5 justify-between items-center mb-4">
            <div className="flex gap-2 items-center">
              <Link to="/acikpozisyonlar" className='p-2 border rounded-full'>
                <ArrowBack />
              </Link>
              <span className="my-auto text-2xl font-bold text-neutral-800">Geri Dön</span>
            </div>
          </div>
        </header>

        <main>
          <h2 className="text-2xl mb-8 font-semibold">Formu doldurarak başvur.</h2>

          <form onSubmit={handleSubmit} className="space-y-6">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div>
                <div className="flex">
                <label htmlFor="firstName" className="block mb-2">İsim</label> <label htmlFor="required" className='text-red-600'>*</label>
                </div>
                <input
                  type="text"
                  id="firstName"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleInputChange}
                  placeholder="Adınızı girin"
                  className="w-full p-3 rounded border border-[#EFEFEE] focus:border-blue-500 focus:outline-none"
                  required
                />
              </div>
              <div>
                <div className="flex">
                <label htmlFor="lastName" className="block mb-2">Soyisim</label><label htmlFor="required" className='text-red-600'>*</label>
                </div>
                <input
                  type="text"
                  id="lastName"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleInputChange}
                  placeholder="Soyadınızı girin"
                  className="w-full p-3 rounded border border-[#EFEFEE] focus:border-blue-500 focus:outline-none"
                  required
                />
              </div>
            
            <div>
            <div className="flex">
              <label htmlFor="email" className="block mb-2">Email</label><label htmlFor="required" className='text-red-600'>*</label>
              </div>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                placeholder="Email adresinizi girin"
                className="w-full p-3 rounded border border-[#EFEFEE] focus:border-blue-500 focus:outline-none"
                required
              />
            </div>
            <div>
            <div className="flex">
              <label htmlFor="phone" className="block mb-2">Telefon</label><label htmlFor="required" className='text-red-600'>*</label>
              </div>
              <input
                type="tel"
                id="phone"
                name="phone"
                value={formData.phone}
                onChange={handleInputChange}
                placeholder="Telefon numaranızı girin"
                className="w-full p-3 rounded border border-[#EFEFEE] focus:border-blue-500 focus:outline-none"
                required
              />
            </div>
            </div>
            
            <div className='grid grid-cols-1 md:grid-cols-3 gap-6'>
            <div >
            <div className="flex">
              <label htmlFor="okul" className="block mb-2">Okul</label><label htmlFor="required" className='text-red-600'>*</label>
              </div>
              <input
                type="text"
                id="okul"
                name="okul"
                value={formData.okul}
                onChange={handleInputChange}
                placeholder="Okulunuzun Adını Giriniz"
                className="w-full p-3 rounded border border-[#EFEFEE] focus:border-blue-500 focus:outline-none"
                required
              />
            </div>
            <div>
            <div className="flex">
              <label htmlFor="bolum" className="block mb-2">Bölüm</label><label htmlFor="required" className='text-red-600'>*</label>
              </div>
             <input
                type="text"
                id="bolum"
                name="bolum"
                value={formData.bolum}
                onChange={handleInputChange}
                placeholder="Bölümünüzü Giriniz"
                className="w-full p-3 rounded border border-[#EFEFEE] focus:border-blue-500 focus:outline-none"
                required
              />
            </div>
            <div>
            <div className="flex">
              <label htmlFor="sinif" className="block mb-2">Sınıf</label><label htmlFor="required" className='text-red-600'>*</label>
              </div>
              <input
                type="text"
                id="sinif"
                name="sinif"
                value={formData.sinif}
                onChange={handleInputChange}
                placeholder="Sınıfınızı Giriniz"
                className="w-full p-3 rounded border border-[#EFEFEE] focus:border-blue-500 focus:outline-none"
                required
              />
            </div>
            </div>
            <div>
              <label htmlFor="linkedin" className="block mb-2">Linkedin</label>
              <input
                type="text"
                id="linkedin"
                name="linkedin"
                value={formData.linkedin}
                onChange={handleInputChange}
                placeholder="LinkedIn profilinizi girin"
                className="w-full p-3 rounded border border-[#EFEFEE] focus:border-blue-500 focus:outline-none"
              />
            </div>
            <label className="col-span-2 text-xs flex gap-2" >
            <input
                type="checkbox"
                id="agreeToTerms"
                name="agreeToTerms"
                checked={formData.agreeToTerms}
                onChange={handleInputChange}
                className=""
                required
              />
              <div className=" flex">
              <div className='text-blue-500 hover:text-blue-700 cursor-pointer underline ' onClick={setShowModal}>Kullanım Koşulları ve Gizlilik Politaksı </div>'nı kabul ediyorum.
              </div>
            </label>
            <button type="submit" className="w-full py-4 bg-[#135361] text-white rounded-full text-lg font-medium hover:bg-[#0f4c4d] transition">
              Başvur
            </button>
          </form>
        </main>
      </div>

      {/* Sağ Taraf: Kart */}
      <aside className="w-full lg:w-1/3 bg-blue-100 p-8 flex items-center justify-center">
        <div className="bg-slate-100 rounded-3xl p-8 max-w-md">
          <h2 className="text-xl font-bold text-neutral-800 mb-1">{job.title}</h2>
          <p className="text-sm text-slate-500 mb-4">{job.department}</p>

          <div className="flex flex-wrap gap-2 mb-8">
            {job.skills.map((skill, index) => (
              <span
                key={index}
                className="px-3 py-1 bg-gray-200 rounded-md text-xs font-medium text-gray-600">
                {skill}
              </span>
            ))}
          </div>
          <hr className="border-gray-200 mb-8" />
          <h3 className="text-2xl font-bold mb-4 text-stone-950">
            UFK Teknoloji Takımına katılarak kendini geliştir.
          </h3>
          <ul className="list-disc pl-5 mb-8 text-sm text-stone-950">
            <li>Takımımıza katılarak, yenilikçi projeler geliştirebilirsiniz</li>
            <li>Yeni beceriler kazan</li>
            <li>Takımımızda, farklı disiplinlerden gelen insanlarla birlikte çalış</li>
            <li>Teknofest ve yarışma deneyimleri elde et</li>
            <li>Networkünü geliştir</li>
          </ul>
          <p className="text-[#9598B2] font-[Objectivity] text-[12px] font-normal leading-[130%] text-center">
            Takımımız üniversite öğrencisi ve mezunlarından oluşan genç ve dinamik bir ekiptir. Maddi çıkar gözetmiyor, gönüllü olarak çalışıyoruz. Kazanırsak beraber kazanacağız, kaybedersek beraber kaybedeceğiz.
          </p>
        </div>
      </aside>
    </div>
  );
}
