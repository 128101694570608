import { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import axios from "axios";
import { LinkedIn } from "@mui/icons-material";
import { ChevronDown } from "lucide-react";

const ApplicationsPage = () => {
  const { jobId } = useParams();
  const [applications, setApplications] = useState([]);
  const [job, setJob] = useState("");
  const [loading, setLoading] = useState(true);
  const [selectedApplicationId, setSelectedApplicationId] = useState(null);

  useEffect(() => {
    const fetchApplications = async () => {
      try {
        const response = await axios.get(`https://ufkrender.onrender.com/api/applications?job_id=${jobId}`);
        setApplications(response.data);
      } catch (error) {
        console.error("Error fetching applications:", error);
      }
    };

    const fetchJobTitle = async () => {
      try {
        const response = await axios.get(`https://ufkrender.onrender.com/jobs/${jobId}`);
        setJob(response.data);
      } catch (error) {
        console.error("Error fetching job title:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchApplications();
    fetchJobTitle();
  }, [jobId]);

  const handleStatusChange = async (applicationId, newStatus) => {
    try {
      await axios.put(`https://ufkrender.onrender.com/api/applications/${applicationId}`, {
        durum: newStatus
      });

      // Re-fetch applications after updating the status
      const response = await axios.get(`https://ufkrender.onrender.com/api/applications?job_id=${jobId}`);
      setApplications(response.data); // Update the state with the latest applications
      setSelectedApplicationId(null)
    } catch (error) {
      console.error("Error updating status:", error);
    }
  };

  const handleDropdownToggle = (applicationId) => {
    setSelectedApplicationId((prevId) => (prevId === applicationId ? null : applicationId));
  };


  if (loading) return <div className="text-center text-lg py-4">Loading...</div>;

  const formatApplicationDate = (dateString) => {
    const applicationDate = new Date(dateString);
    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      timeZone: 'Europe/Istanbul',
      hour12: false
    };
    return new Intl.DateTimeFormat('tr-TR', options).format(applicationDate);
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-semibold mb-6">Başvuru Listesi: {job.title}</h1> 
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {applications.map((application, index) => (
          <div
            key={index}
            className="bg-white shadow-lg rounded-lg p-6 border border-gray-200 relative"
          >
            <div className="absolute top-2 right-2 flex gap-2 items-center rounded-full border p-2 " >
              <button
                className={`w-4 h-4 ${application.durum === "1" ? "bg-green-500" : "hidden"} rounded-full`}
                title="Yeni Başvuru"
                onClick={() => handleDropdownToggle(application._id)}
              ></button>
              <button
                className={`w-4 h-4 ${application.durum === "2" ? "bg-yellow-500" : "hidden"} rounded-full`}
                title="İncelemede"
                onClick={() => handleDropdownToggle(application._id)}
              ></button>
              <button
                className={`w-4 h-4 ${application.durum === "3" ? "bg-red-500" : "hidden"} rounded-full`}
                title="İncelendi"
                onClick={() => handleDropdownToggle(application._id)}
              ></button>

              <ChevronDown size={20} onClick={() => handleDropdownToggle(application._id)} />

              {selectedApplicationId === application._id && (
                <div className="absolute top-10 right-0 bg-white border rounded shadow-md  w-40 mx-auto ">
                  <button
                    onClick={() => handleStatusChange(application._id, "1")}
                    className="px-4 flex items-center gap-2 py-2 text-sm text-green-600 hover:bg-green-100 w-full"
                  >
                    <div className={`w-4 h-4 bg-green-500 rounded-full`}></div>
                    Yeni Başvuru
                  </button>
                  <button
                    onClick={() => handleStatusChange(application._id, "2")}
                    className="px-4 flex items-center gap-2 py-2 text-sm text-yellow-600 hover:bg-yellow-100 w-full"
                  >
                    <div className={`w-4 h-4 bg-yellow-500 rounded-full`}></div>
                    İncelemede
                  </button>
                  <button
                    onClick={() => handleStatusChange(application._id, "3")}
                    className="px-4 flex items-center gap-2 py-2 text-sm text-red-600 hover:bg-red-100 w-full"
                  >
                    <div className={`w-4 h-4 bg-red-500 rounded-full`}></div>
                    İncelendi
                  </button>
                </div>
              )}
            </div>

            <h2 className="text-xl font-bold mb-2 flex items-center">{application.firstName} {application.lastName}</h2>
            <p className="text-gray-600 mb-2 flex items-center">
              <strong>Telefon:</strong> {application.phone}
            </p>
            <p className="text-gray-600 mb-2 flex items-center">
              <strong>Email:</strong> {application.email}
            </p>
            <p className="text-gray-600 mb-2 flex items-center">
              <strong>Tarih:</strong> {formatApplicationDate(application.application_date)}
            </p>
            <p className="text-gray-600 mb-2 flex items-center">
              <strong>Okul:</strong> {application.okul}
            </p>
            <p className="text-gray-600 mb-2 flex items-center">
              <strong>Bölüm:</strong> {application.bolum}
            </p>
            <p className="text-gray-600 mb-2 flex items-center">
              <strong>Sınıf:</strong> {application.sinif}
            </p>
            <p className="text-gray-600 mb-2 flex items-center">
  <strong>LinkedIn:</strong> 
  {application.linkedin ? (
    <Link
      to={`${application.linkedin}`}
      target="_blank"
      rel="noopener noreferrer"
      className="text-blue-600 hover:underline"
    >
      <LinkedIn />
    </Link>
  ) : (
    <Link
      aria-disabled
    >
      <LinkedIn />
    </Link>
  )}
</p>

            
          </div>
        ))}
      </div>
    </div>
  );
};

export default ApplicationsPage;
