import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Alert } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import Logo from '../Components/Logo.png';
import Duyuru from '../Components/Duyuru';

function RegisterPage() {
  const [name, setName] = useState('');
  const [surname, setSurname] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [acceptDataUsage, setAcceptDataUsage] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false); 

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      axios.get('https://ufkrender.onrender.com/verify-token', { headers: { Authorization: `Bearer ${token}` } })
        .then(() => {
          navigate('/profile');
        })
        .catch(() => {
          localStorage.removeItem('token');
        });
    }
  }, [navigate]);

  const onSubmit = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setError("Şifreler uyuşmuyor");
      return;
    }
    if (!acceptDataUsage) {
      setError("Lütfen tüm kutucukları işaretleyin");
      return;
    }
    try {
      await axios.post('https://ufkrender.onrender.com/register', { name, surname, email, password });
      setError(null);
      alert('Kayıt başarılı. Lütfen e-posta adresinizi doğrulayın.');
    } catch (error) {
      if (error.response && error.response.status === 409) {
        // Show an error if the email is already registered
        setError("Bu e-posta ile kayıtlı bir kullanıcı zaten var.");
      } else {
        // Handle other errors
        setError(error.response.data.message || "Kayıt sırasında bir hata oluştu.");
      }
    }
  };


  return (
    <div className="h-screen">

      <Duyuru isOpenByDefault={false} trigger={showModal} overflow={true} >
      <div className="">
      <h1 className="text-2xl font-bold mb-4">Kullanım Koşulları</h1>
      <h2 className="text-xl font-semibold mb-2">1. Genel Hükümler</h2>
      <p className="mb-4">
        Bu web sitesini ziyaret eden veya kullanan tüm kullanıcılar, aşağıda belirtilen koşulları kabul etmiş sayılır. UFK Teknoloji, önceden haber vermeksizin bu kullanım koşullarında değişiklik yapma hakkını saklı tutar. Güncellenmiş koşullar, sitede yayınlandığı tarihten itibaren geçerli olacaktır.
      </p>
      <h2 className="text-xl font-semibold mb-2">2. Fikri Mülkiyet Hakları</h2>
      <p className="mb-4">
        Web sitemizde yer alan tüm içerik, görseller, logolar, grafikler ve diğer materyaller, telif hakkı ve diğer fikri mülkiyet hakları kapsamında korunmaktadır. İzinsiz kopyalanması, dağıtılması veya kullanılması yasaktır.
      </p>
      <h2 className="text-xl font-semibold mb-2">3. Kullanıcı Sorumluluğu</h2>
      <p className="mb-4">
        Kullanıcılar, web sitesini hukuka aykırı bir şekilde kullanmamayı, üçüncü şahısların haklarına zarar vermemeyi ve UFK Teknoloji'ye ait sistemlere zarar verecek eylemlerden kaçınmayı kabul ederler.
      </p>
      <h2 className="text-xl font-semibold mb-2">4. Hizmetlerin Kesintisizliği</h2>
      <p className="mb-4">
        UFK Teknoloji, web sitesinin sürekli olarak erişilebilir olmasını sağlamak için elinden geleni yapar, ancak teknik arızalar, bakım çalışmaları veya başka nedenlerle hizmetlerde kesintiler yaşanabilir. UFK Teknoloji, bu tür durumlarda sorumluluk kabul etmez.
      </p>
      <h2 className="text-xl font-semibold mb-2">5. Üçüncü Taraf Bağlantıları</h2>
      <p className="mb-4">
        Web sitemizde üçüncü taraf web sitelerine yönlendiren bağlantılar bulunabilir. Bu sitelerin içeriklerinden veya gizlilik uygulamalarından UFK Teknoloji sorumlu değildir.
      </p>

      <h1 className="text-2xl font-bold mt-8 mb-4">Gizlilik Politikası</h1>
      <h2 className="text-xl font-semibold mb-2">1. Toplanan Bilgiler</h2>
      <p className="mb-4">
        UFK Teknoloji, web sitesi üzerinden yalnızca e-posta adresi ve telefon numarası gibi kullanıcıların iletişim bilgilerini toplar. Bu bilgiler, yalnızca hesap doğrulama ve kullanıcıyla iletişim amacıyla kullanılır. Ticari amaçlı herhangi bir veri işleme yapılmaz.
      </p>
      <h2 className="text-xl font-semibold mb-2">2. Verilerin Kullanımı</h2>
      <p className="mb-4">
        Topladığımız kişisel veriler, kullanıcılara hizmet sunabilmek ve hesap işlemlerini doğrulamak amacıyla kullanılır. Verileriniz, izniniz olmadan üçüncü kişilerle paylaşılmayacaktır.
      </p>
      <h2 className="text-xl font-semibold mb-2">3. Verilerin Korunması</h2>
      <p className="mb-4">
        Kişisel verilerinizin güvenliği için gerekli tüm teknik ve idari önlemler alınmaktadır. Bilgileriniz, yetkisiz erişime, kaybolmaya veya kötüye kullanıma karşı korunmaktadır.
      </p>
      <h2 className="text-xl font-semibold mb-2">4. Kullanıcı Hakları</h2>
      <p className="mb-4">
        6698 sayılı Kişisel Verilerin Korunması Kanunu kapsamında, kullanıcılar topladıkları verilerin silinmesini, düzeltilmesini veya erişilmesini talep edebilirler. Bu haklarınızı kullanmak için bizimle iletişime geçebilirsiniz.
      </p>
      <h2 className="text-xl font-semibold mb-2">5. Çerezler</h2>
      <p className="mb-4">
        Web sitemiz, kullanıcı deneyimini iyileştirmek için çerezler kullanabilir. Çerezler, kullanıcıların site üzerindeki davranışlarını izlemek veya web trafiğini analiz etmek için kullanılmaktadır.
      </p>
      <h2 className="text-xl font-semibold mb-2">6. Verilerin Saklanma Süresi</h2>
      <p className="mb-4">
        Toplanan kişisel veriler, işlenme amacının gerektirdiği süre boyunca veya yasal gereklilikler çerçevesinde saklanacaktır. Verileriniz, işlenme amacı sona erdiğinde güvenli bir şekilde imha edilecektir.
      </p>
    </div>
      </Duyuru>



      <div className="fixed p-5 flex items-center w-full">
      <Link to="/" className='flex gap-3 items-center'>
        <img src={Logo} alt="Logo" className='h-10 ' />
        <div className="flex gap-1 mt-2">
          <p className='text-[#ED762F] font-black'>UFK</p>
          <p className='text-[#135361] font-semibold'>Teknoloji Takımı</p>
        </div>
        </Link>
        <div className="ml-auto">
          <Link to="/login">
            <button className='bg-[#ED762F] text-white font-bold py-2 px-4 font-inter text-sm rounded-xl hover:bg-secondary duration-300'>Oturum Aç</button>
          </Link>
        </div>
      
      </div>
      {error && <Alert severity="error" className='fixed left-1/2 -translate-x-1/2 w-1/4 justify-center '>{error} <div className="fixed right-2 top-1 cursor-pointer" onClick={()=>setError(null)}>X</div> </Alert>}
      
      <div className="flex items-center h-full p-auto ">
        <div className="grid gap-5 m-auto rounded-3xl py-12">
          <h1 className='text-6xl w-96 font-inter font-bold'>Kayıt Ol</h1>
          <div className='h-0.5 w-full bg-gray-100'/>
          <p className='text-sm font-semibold mr-auto'>Kayıt için bilgilerinizi girin.</p>
          <form onSubmit={onSubmit} className="grid gap-3 grid-cols-2 max-w-sm">
            <input type="text" placeholder="İsim" value={name} onChange={(e) => setName(e.target.value)} className="bg-gray-100 focus:outline-none w-full h-10 rounded-lg p-2" required />
            <input type="text" placeholder="Soyisim" value={surname} onChange={(e) => setSurname(e.target.value)} className="bg-gray-100 focus:outline-none w-full h-10 rounded-lg p-2" required />
            <input type="email" placeholder="E-posta" value={email} onChange={(e) => setEmail(e.target.value)} className="bg-gray-100 focus:outline-none w-full h-10 rounded-lg p-2 col-span-2" required />
            <input type="password" placeholder="Şifre" value={password} onChange={(e) => setPassword(e.target.value)} className="bg-gray-100 focus:outline-none w-full h-10 rounded-lg p-2 col-span-2" required />
            <input type="password" placeholder="Şifreyi Tekrar Girin" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} className="bg-gray-100 focus:outline-none w-full h-10 rounded-lg p-2 col-span-2" required />
            <label className="col-span-2 text-xs flex gap-2" >
              <input type="checkbox" checked={acceptDataUsage} onChange={() => setAcceptDataUsage(!acceptDataUsage)} required/>
              <div className=" flex">
              <div className='text-blue-500 hover:text-blue-700 cursor-pointer underline ' onClick={setShowModal}>Kullanım Koşulları ve Gizlilik Politaksı </div>'nı kabul ediyorum.
              </div>
            </label>

            <button type="submit" className="bg-[#135361] font-bold text-white w-full h-10 col-span-2 font-inter rounded-lg">Kayıt Ol</button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default RegisterPage;
